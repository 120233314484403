import React from 'react';
//import Digitalindia from '../../assets/Digitalindia/digitalindia.png'
import Startup from '../../assets/Supportedby/Startup.png'
import Roadcongress from '../../assets/Supportedby/Roadcongress.png'
import Makeinindia from '../../assets/Supportedby/Makeinindia.png'
import Maharashtra from '../../assets/Supportedby/Maharashtra.png'
import Digital from '../../assets/Supportedby/Digital.png'
import Astm from '../../assets/Supportedby/Astm.png'
import Nvidia from '../../assets/Supportedby/Nvidia.png'
import Nascom from '../../assets/Supportedby/nascom.png'
import Google from '../../assets/Supportedby/google.png'
import Calcutta from '../../assets/Supportedby/calcutta.png'
import Josh from '../../assets/Supportedby/josh.png'

import './SupportedPage.css'

function SupportedPage() {
  return (
    <div>
    <div className="w-full h-[40vh] md:h-[45vh] flex justify-evenly items-center flex-col " style={{ marginTop: '-10vh' }}>
        <div className="w-[80%]  font-semibold text-xl md:text-4xl flex justify-center items-center   font-dmsans  "style={{fontWeight: 600, color: "#00836C"}}>
          Our Early Supporters
        </div>
        <div className="container">
           <div className="slide-container">
              
               <div className="slide-image">
                  <img 
                  src={Digital}
                  alt=" digital Icon"
                  />
               </div>
              
               <div className="slide-image flex justify-center items-center">
                  <img 
                  src={Maharashtra}
                  alt=" maharashtra Icon"
                  
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Startup}
                  alt=" startup Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Makeinindia}
                  alt=" makinindia Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Roadcongress}
                  alt=" roadcongress Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Astm}
                  alt=" astm Icon"
                  />
               </div>

               

                  <div className="slide-image">
                  <img 
                  src={Google}
                  alt=" Nvidia Icon"
                  />
                  </div>

                  <div className="slide-image">
                  <img 
                  src={Calcutta}
                  alt=" Nvidia Icon"
                  />
                  </div>

                  <div className="slide-image">
                  <img 
                  src={Josh}
                  alt=" Nvidia Icon"
                  />
                  </div>

                  <div className="slide-image">
                  <img 
                  src={Nvidia}
                  alt=" Nvidia Icon"
                  />
                  </div>

                  <div className="slide-image">
                  <img 
                  src={Digital}
                  alt=" digital Icon"
                  />
               </div>
              
               <div className="slide-image flex justify-center items-center">
                  <img 
                  src={Maharashtra}
                  alt=" maharashtra Icon"
                  
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Startup}
                  alt=" startup Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Makeinindia}
                  alt=" makinindia Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Roadcongress}
                  alt=" roadcongress Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Astm}
                  alt=" astm Icon"
                  />
               </div>

               

                  <div className="slide-image">
                  <img 
                  src={Google}
                  alt=" Nvidia Icon"
                  />
                  </div>

                  <div className="slide-image">
                  <img 
                  src={Calcutta}
                  alt=" Nvidia Icon"
                  />
                  </div>

                  <div className="slide-image">
                  <img 
                  src={Josh}
                  alt=" Nvidia Icon"
                  />
                  </div>

                  <div className="slide-image">
                  <img 
                  src={Nvidia}
                  alt=" Nvidia Icon"
                  />
                  </div>
               </div>
               </div>
    </div>
    </div>
  );
}

export default SupportedPage;


