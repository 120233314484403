import React from 'react';
import { TbPointFilled } from 'react-icons/tb';
import { MdOutlineRemoveRoad } from 'react-icons/md';
import { MdAddRoad } from 'react-icons/md';
import { FaRoad } from 'react-icons/fa6';
import keyboard from '../../assets/defect/keyboard.png'
import './Defectcard.css'
const distressData = [
	{
		title: 'Distress Detection',
		icon: <FaRoad />,
		details: [
			{
				type: 'Potholes:',
				description:
					 'Minor, Moderate, And Severe',
			},
			{
				type: 'Cracks:',
				description: 'Alligator, Longitudinal, Transverse',
			},
			{
				type: 'Revelling:',
				description: 'Moderate and Severe',
			},
			{
				type: 'Repair Hotspot:',
				description: 'Level 1, Level 2, Level 3 ',
			},
			{
				type: 'Other Distress:',
				description: 'Bleeding,Rutting,Patches etc',
			},
		],
	},
	{
		title: 'Asset Detection',
		icon: < MdAddRoad />,
		details: [
			{
				type: 'Bridges:',
				description: 'Major,Minor,Flyover,Culverts,CD Works',
			},
			{
				type: 'Surface Assets:',
				description: 'Manhole,Bumps,Crosswalks',

			},
			{
				type: 'Inventory:',
				description: 'Streetlamps,Signboards,Trees,Signals,Busstop',
				
			},
		],
	},
	{
		title: 'Other Detection',
		icon: <MdOutlineRemoveRoad />,
		details: [
			{
			
				description:
					'Footpath,Work in Progress,Encroachment,Traffic Icelands,Hoardings,Beautification Suggestions',
			},
			
		],
	},
];

const DefectCard = () => {
	const getIcon = (index) => {
		switch (index) {
			case 0:
				return <img src={keyboard} alt="Keyboard" className="h-5 w-5 md:h-8 md:w-8" />;
			case 1:
				return < MdAddRoad className="text-green-800 h-5 w-5 md:h-8 md:w-8" />;
			case 2:
				return <MdOutlineRemoveRoad className="text-green-800 h-5 w-5 md:h-8 md:w-8" />;
			default:
				return <TbPointFilled className="text-green-800 h-5 w-5 md:h-8 md:w-8" />;
		}
	};
	return (
		<div className="flex flex-col w-[95%] justify-evenly  p-0 md:p-3 mx-auto font-opensans pb-12 ml-50 mb-20">
			{distressData.map((distress, index) => (
				<div key={index} className="flex w-full my-4 ">
					<div className="w-12 ">
						{getIcon(index)}
					</div>
					<div className="flex-1">
						<p className="distresstitle font-josefin text-base md:text-2xl ">
							{distress.title}
						</p>
						<div>
							<div className="flex flex-col justify-center items-start mt-2  ">
								{distress.details.map((detail, detailIndex) => (
									<div
										key={detailIndex}
										className="flex items-center gap-x-0 md:gap-x-2">
										<p>
											<TbPointFilled className="rounded-full " />
										</p>
										<div className="distresstype  flex gap-x-2 text-xs md:text-base">
											<span className="font-semibold whitespace-nowrap ">
												{detail.type} 
											</span>
											<p className=" mooving flex-wrap   font-light">
												{detail.description}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default DefectCard;
