import React,{useState} from 'react'
import { Link } from 'react-router-dom';
 import { IoRocket } from "react-icons/io5";
 import { IoEye } from "react-icons/io5";
 import { IoDiamond } from "react-icons/io5";
 import { TbCircleLetterXFilled } from 'react-icons/tb';
 import Footer1 from '../Footer/Footer1';


function Aboutus({ onMenuToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  return (
   <>
   
   <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start"style={{ color: '#00836C'}}>
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>

<div className="text-center mt-20 text-xl md:text-4xl text-green-700 text-semibold font-opensans " style={{ fontWeight: 500 }}>
        <h2>About Us</h2>
        
      </div>

      <div classname="text-4xl  bg-white font-opensans mt-10">
        <p className='px-8 md:px-40 text-justify md:text-normal mt-10 font-semibold text-gray-400 text-base bg-'>
        At Rasta AI, we’re dedicated to transforming road condition monitoring and asset
 management with advanced, AI-driven solutions that ensure safe, pothole-free 
roads for communities and authorities worldwide. Our innovative tools provide 
accurate, cost-effective insights, empowering users without the need for 
professional training or expensive survey equipment.
We offer a range of portable solutions, including advanced road condition 
detection, asset management, and analytics, all designed to simplify the 
monitoring and maintenance process. By providing actionable insights and 
virtual inspections, we help communities and authorities proactively manage 
road conditions, ensuring road safety and sustainability.
Our commitment to innovation and accessibility drives us to create cutting-edge 
technologies that foster global road safety, reduce infrastructure costs, and 
promote community engagement. Join us in our mission to make every road a 
safer, smoother journey for everyone.
        </p>
      </div>
      <div className="pb-10 md:pb-20 text-center md:px-8 bg-gry-100 mt-10">
  <div className="flex flex-col md:flex-row justify-between items-start p-6 space-y-6 md:space-y-0 md:space-x-6">
    <div className="bg-green-200 py-20 rounded shadow-md w-full md:w-1/3 flex flex-col">
    <div className="flex justify-center items-center text-green-700">
      <IoRocket className="text-4xl" />
    </div>
     
      <h2 className="text-xl font-bold mb-4">Mission</h2>
      
      <p className="text-gray-700 flex-grow text-justify px-4">Our mission is to revolutionize road condition monitoring and infrastructure management globally. We provide advanced, AI-driven solutions that empower communities and authorities to maintain safe, pothole-free roads efficiently and affordably. By eliminating barriers of cost and complexity, we make our technology accessible on a broader scale, promoting sustainable development and improving road safety globally, and enhancing the safety of individuals worldwide.





</p>
    </div>
    <div className="bg-green-200 py-20 rounded shadow-md w-full md:w-1/3 flex flex-col">
    <div className="flex justify-center items-center text-green-700">
      <IoEye  className="text-4xl" />
    </div>
      <h2 className="text-xl font-bold mb-4">Vision</h2>
      <p className="text-gray-700 flex-grow text-justify px-4">Our vision is to spearhead the transformation of global road safety and infrastructure management through innovative, AI-powered solutions. We aim to provide accessible, cost-effective tools to empower communities and authorities, ensuring every road is safe, pothole-free, sustainable, resilient, and efficient. By leveraging AI and advanced analytics, we aim to set new benchmarks in road condition monitoring, fostering safer journeys and improving the overall quality of life.





</p>
    </div>
    <div className="bg-green-200 py-20 rounded shadow-md w-full md:w-1/3 flex flex-col">
    <div className="flex justify-center items-center text-green-700">
      <IoDiamond  className="text-4xl" />
    </div>
      <h2 className="text-xl font-bold mb-4 ">Values</h2>
      <p className="text-gray-700 flex-grow text-justify px-4">

      We are committed to delivering the highest quality products and services to our clients. We believe in transparency, honesty, and integrity in all our dealings. Our values drive us to constantly innovate and improve, ensuring that we meet the evolving needs of our clients. We foster a culture of collaboration and respect, recognizing that our success is built on the strength of our diverse and talented team. We strive to make a positive impact in our communities and the world at large.


</p>
    </div>
  </div>
</div>

<div className=' md:-[mt]-10 '>
<Footer1 />
</div>
   </>
  )
}

export default Aboutus