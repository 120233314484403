import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TbCircleLetterXFilled } from 'react-icons/tb';
import '../HomePage/Navbar/NavbarStyle.css';
import '../OurProducts/OurNavbarStyle.css';

const OurNavbar = ({ onMenuToggle }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };

  return (
    <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start "style={{ color: '#00836C'}}>
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>
  );
};

export default OurNavbar;
