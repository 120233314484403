import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import Comingsoon from '../../assets/Comingsoon/comingsoon.mp4'
import '../HomePage/Navbar/NavbarStyle.css'
import Comingsoon2 from '../../assets/Comingsoon/comingsoon2.mp4'
import Comingsoonnew from '../../assets/Comingsoon/comingsoonnew.mov'
import { TbCircleLetterXFilled } from 'react-icons/tb';
import Footer1 from '../Footer/Footer1';

function Demo({ onMenuToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  return (
    <>
    <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start"style={{ color: '#00836C'}}>
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>
   
 <div className="text-center mt-16 md:mt-20 text-3xl md:text-4xl text-green-700 text-semibold font-josefin" style={{ fontWeight: 500 }}>
        <h2>Demo</h2>
        
      </div> 

      <div className="flex flex-col md:flex-row h-90 border border-gray-300 mt-20 mx-4 md:mx-20 ">
  <div className="md:w-1/2 w-full bg-black flex items-center justify-center ">
    <video
      className="w-full h-auto"
      controls
      autoPlay
      loop
      src={Comingsoon}
    ></video>
  </div>

  <div className="md:w-1/2 w-full bg-gray-100 flex flex-col items-center justify-center "> {/* Changed flex to flex-col */}
    <div className="px-4 text-center"> {/* Added text-center class here */}
      <div className="mt- font-josefin">
        <span className="text-gray-500 font-bold text-xl md:text-3xl">Rasta</span>
        <span className="text-orange-500 font-bold text-xl md:text-3xl">.360&deg; </span>
        <span className="text-green-500 font-bold text-xl md:text-3xl" style={{ color: "#00836C" }}>
          Enterprise
        </span>
      </div>
      <p className="text-xs md:text-base mt-4 md:mt-0 font-opensans">
        Enterprise-Grade Road Monitoring Solutions
      </p>

      <p className="text-xs md:text-base mb-2 mt-6 font-opensans text-justify">
        Rasta360 Enterprise delivers advanced AI-powered analysis and 12K resolution imagery for detailed road inspections. It offers comprehensive reporting and pothole prediction before they occur, along with seasonal monitoring to ensure continuous road quality. Seamlessly integrating with existing systems, Rasta360 Enterprise ensures precise, reliable results for efficient road management.
      </p>

      <div className="flex justify-center">
        <Link to="/Contact">
          <button
            className="bg-green-500 text-white text-xs md:text-xl font-semibold py-2 px-6 md:px-8 rounded-xl mt-4 mb-2 md:mb-0"
            style={{ backgroundColor: '#00836C', fontWeight: 480 }}
          >
            Connect
          </button>
        </Link>
      </div>
    </div>
  </div>
</div>


<div className="flex flex-col md:flex-row h-90 border border-gray-300 mt-20 mx-4 md:mx-20">
  <div className="md:w-1/2 w-full p-4 bg-gray-100 flex flex-col items-center justify-center text-center">
    <div className="text-center mt- font-josefin">
      <span className="text-gray-500 font-bold text-xl md:text-3xl">Rasta</span>
      <span className="text-orange-500 font-bold text-xl md:text-3xl">.360&deg; </span>
      <span className="text-green-500 font-bold text-xl md:text-3xl" style={{ color: "#00836C" }}>Mini</span>
      <p className="text-xs md:text-base mt- font-opensans" style={{ fontWeight: 350 }}>Portable and Efficient Road Monitoring</p>
    </div>
    <p className="text-xs md:text-base mb-2 mt-6 font-opensans text-justify">
      Rasta360 Mini is a portable road condition monitoring solution with advanced AI analysis for accurate results. It eliminates the need for special survey vehicles, allowing surveys using a bike or any car at any speed. User-friendly data collection and uploading ensure smooth and efficient road inspections.
    </p>
    <div className="flex justify-center">
      <Link to="/Contact">
        <button className="bg-green-500 text-white text-xs md:text-xl font-semibold py-2 px-6 md:px-8 rounded-xl mt-4 mb-2 md:mb-0" style={{ backgroundColor: '#00836C', fontWeight: 480 }}>
          Connect
        </button>
      </Link>
    </div>
  </div>

  <div className="md:w-1/2 w-full bg-black flex items-center justify-center">
    <video
      className="w-full h-auto"
      controls
      autoPlay
      loop  // Add autoPlay attribute here
      src={Comingsoon2}
    ></video>
  </div>
</div>

<div className="flex flex-col md:flex-row h-90 border border-gray-300 mt-20 mb-20 mx-4 md:mx-20">
  <div className="md:w-1/2 w-full bg-black flex items-center justify-center">
    <video
      className="w-full h-auto"
      autoPlay 
      loop // Added autoplay attribute
      controls
      src={Comingsoonnew}
    ></video>
  </div>

  <div className="md:w-1/2 w-full p bg-gray-100 flex flex-col items-center justify-center"> 
    <div className="px-4 text-center">
      <div className="font-josefin">
        <span className="text-gray-500 font-bold text-xl md:text-3xl">Rasta</span>
        <span className="text-orange-500 font-bold text-xl md:text-3xl">.AI</span>
      </div>
      <p className="text-xs md:text-base font-opensans" style={{ fontWeight: 350 }}>
        Smart Road Monitoring on the Go
      </p>

      <p className="text-xs md:text-base mb-2 mt-6 font-opensans text-justify">
        The Rasta.ai mobile app offers AI-powered analysis for precise road issue detection and real-time data processing. It features comprehensive reports and an intuitive, user-friendly interface, making road monitoring quick and efficient. Perfect for on-the-go surveys and maintenance planning.
      </p>

      <Link to="/Contact">
        <button className="bg-green-500 text-white text-xs md:text-xl font-semibold py-2 px-6 md:px-8 rounded-xl mt-4 mb-2 md:mb-0" style={{ backgroundColor: '#00836C', fontWeight: 480 }}>
          Connect
        </button>
      </Link>
    </div>
  </div>
</div>

<Footer1 />
</>
  )
}

export default Demo