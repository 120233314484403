import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import { TbCircleLetterXFilled } from 'react-icons/tb';
import Footer1 from '../Footer/Footer1';

function Termsosuse({ onMenuToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  return (
   <>
   
   <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>

<div className="bg-Honeydew relative pt-4 pb-8 mt-20">
			<h2 className="text-center text-2xl md:text-4xl font-josefin mt-5 text-green-800 font-medium">
				Terms of Service
			</h2>
			<h2 className="text-center text-base md:text-xl font-josefin mt-5 text-green-800 font-medium">
			for rasta-Ai.com / rasta360.com
			</h2>
			<h2 className="text-center text-base md:text-xl font-josefin mt-5 text-green-800 font-medium">
			Effective Date: 13/06/2024
			</h2>
			</div>


      <div className="p-8 px-8 md:px-40 bg-gray-100 font-josefin text-justify md:text-normal">

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">1. Introduction and Acceptance</h2>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          These Terms of Use ("Terms") govern the use of the rasta-Ai.com website (the "Website") and the services provided by Ai Unika Technologies (P) Limited. By using the Website, you agree to comply with these Terms. If you do not agree to these Terms, please refrain from using the Website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">2. Definitions</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li><strong>“Content”</strong> refers to text, images, videos, audio, or other multimedia elements uploaded to or accessible through the Website.</li>
          <li><strong>“User”</strong> refers to anyone accessing the Website.</li>
          <li><strong>“Service”</strong> refers to all products, services, or tools offered through the Website.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">3. Use of the Website</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>You must use the Website in a lawful and responsible manner.</li>
          <li>You agree not to engage in activities that could harm the Website, its infrastructure, or other users, such as attempting to hack, phishing, or other unauthorized activities.</li>
          <li>You acknowledge that certain features and services may be restricted based on geographical location or other criteria.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">4. User Accounts</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>To use certain features, you may be required to create an account.</li>
          <li>You are responsible for maintaining the confidentiality of your account information and password.</li>
          <li>Any unauthorized use of your account must be reported promptly.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">5. Privacy</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Ai Unika Technologies (P) Limited is committed to protecting your privacy. The collection and use of your personal data are governed by our Privacy Policy.</li>
          <li>You consent to the collection and processing of your personal data as described in our Privacy Policy.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">6. Intellectual Property</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>All content on the Website, including text, images, logos, software, and other intellectual property, is the property of Ai Unika Technologies (P) Limited or its licensors.</li>
          <li>You may not use, reproduce, or distribute any content without explicit written consent from Ai Unika Technologies (P) Limited.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">7. Third-Party Links</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>The Website may contain links to third-party websites or services that are not owned or controlled by Ai Unika Technologies (P) Limited.</li>
          <li>Ai Unika Technologies (P) Limited assumes no responsibility for the content, privacy policies, or practices of third-party websites or services.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">8. Limitation of Liability</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}> 
          <li>Ai Unika Technologies (P) Limited shall not be liable for any indirect, incidental, consequential, or punitive damages arising from the use of the Website or the inability to use it.</li>
          <li>This includes, but is not limited to, damages for loss of profits, data, or other intangible losses.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">9. Modification and Termination of Services</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Ai Unika Technologies (P) Limited reserves the right to modify, suspend, or discontinue any aspect of the Website or services at any time, with or without notice.</li>
          <li>You acknowledge and agree that Ai Unika Technologies (P) Limited will not be liable to you or any third party for any modification, suspension, or discontinuance of the Website or services.</li>
          <li>You may terminate your account and discontinue using the Website at any time.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">10. Governing Law and Jurisdiction</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>These Terms of Use will be governed by and construed in accordance with the laws of Pune, India.</li>
          <li>Any disputes arising out of or in connection with these Terms or your use of the Website will be subject to the exclusive jurisdiction of the courts in Pune, India.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">11. Dispute Resolution</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Any disputes arising out of or related to these Terms of Use shall be resolved through binding arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996.</li>
          <li>The arbitration shall be conducted in Pune, India, and the proceedings shall be confidential.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">12. Indemnification</h2>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          You agree to indemnify, defend, and hold harmless Ai Unika Technologies (P) Limited, its officers, directors, employees, agents, and affiliates from and against any and all claims, losses, damages, expenses, and liabilities, including legal fees, arising out of or related to your use of the Website or violation of these Terms of Use.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">13. Severability</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>If any provision of these Terms of Use is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.</li>
          <li>The invalid or unenforceable provision will be replaced with a valid and enforceable provision that most closely matches the intent of the original provision.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">14. Miscellaneous Provisions</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>These Terms of Use represent the entire agreement between you and Ai Unika Technologies (P) Limited regarding the use of the Website.</li>
          <li>Any amendments or modifications to these Terms must be in writing and signed by both parties.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-base font-semibold mb-4">16. Changes to Terms of Use</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Ai Unika Technologies (P) Limited reserves the right to modify, revise, or update these Terms of Use at any time. We will notify you of any changes by posting the updated Terms on the Website and updating the effective date.</li>
          <li>Your continued use of the Website after any changes to the Terms of Use constitutes acceptance of the updated terms.</li>
        </ul>
      </section>

      <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">17. Force Majeure</h2>
          <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
            Ai Unika Technologies (P) Limited shall not be liable for any delay or failure in performance under these Terms of Use if such delay or failure arises from any cause beyond its reasonable control, including but not limited to acts of God, governmental actions, war, terrorism, riots, earthquakes, floods, and other natural disasters.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">18. User Content and Feedback</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>If you provide feedback, comments, or suggestions regarding the Website or services, Ai Unika Technologies (P) Limited may use and share such feedback, comments, or suggestions for any purpose without any obligation or compensation to you.</li>
            <li>You retain ownership of any content you submit to the Website, provided it complies with these Terms.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">19. Communication Preferences</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>By creating an account on the Website or using the services, you consent to receive communication from Ai Unika Technologies (P) Limited, including email, SMS, or other forms of communication, regarding account updates, promotional offers, or important announcements.</li>
            <li>You can manage your communication preferences through your account settings.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">20. Miscellaneous Terms</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>These Terms of Use are binding and enforceable against you and your legal representatives, successors, and assigns.</li>
            <li>You may not assign or transfer your rights or obligations under these Terms of Use without the prior written consent of Ai Unika Technologies (P) Limited.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">21. Accessibility</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>Ai Unika Technologies (P) Limited is committed to ensuring that the Website and its services are accessible to all users. If you encounter any difficulty accessing the Website or utilizing any features, please contact our support team for assistance.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">22. Third-Party Content and Integration</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>The Website may contain content or integrations with third-party services or websites. Ai Unika Technologies (P) Limited does not endorse or assume responsibility for the content, products, services, or materials available on third-party platforms.</li>
            <li>Your use of third-party services or websites is subject to the terms of service and privacy policies of those third parties.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">23. Notifications and Announcements</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>Ai Unika Technologies (P) Limited may send notifications and announcements, including updates, account status, and promotional offers, via email or other communication channels to registered users. These communications are essential for account management and service updates.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">24. No Waiver</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>Ai Unika Technologies (P) Limited's failure to enforce any provision of these Terms of Use does not constitute a waiver of such provision or its rights under these Terms.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">25. Interpretation</h2>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>The headings and section titles used in these Terms of Use are for reference purposes only and have no legal or contractual significance.</li>
            <li>The terms and provisions of these Terms of Use should be interpreted fairly and in good faith, considering the overall intent and purpose.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-4">26. Contact Information</h2>
          <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
            If you have any questions or concerns regarding these Terms of Use, please contact us at:
          </p>
          <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
            <li>Ai Unika Technologies (P) Limited</li>
            <li>903, Rajiv Gandhi Infotech Park, Hinjewadi Phase 2, Pune (MH) India 57</li>
            <li>Email: support@rasta-aiunika.com</li>
            <li>Phone: [Insert Phone Number]</li>
          </ul>
        </section>

        <section className="mb-10">
          <h2 className="text-base font-semibold mb-4">27. Consent of User</h2>
          <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
            I have read and understood the Terms of Use for rasta-ai.com and rasta360.com. I accept and give my consent to be bound by these terms and conditions in my use of the Websites. I understand that my continued use of the Websites signifies my agreement to these Terms.
          </p>
        </section>

        <p className="text-sm text-sm font-light"style={{fontWeight: 350}}>
          Effective Date – 13/06/2024
        </p>


      </div>
      <Footer1 />

   </>
  )
}

export default Termsosuse