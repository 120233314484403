import './App.css';
 import Layout from './components/Layout/Layout';
 import OurProductsLayout from './components/OurProducts/OurProductsLayout';
 import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
 import Faq from './components/FAQ/Faq';
 import Demo from './components/Demo/Demo';
 import Appointment from './components/Contact/Appointment';
import ScrollToTop from './components/ScrollToTop';
import GoToTopButton from './components/Gototop/Gototop';
import Privacypolicy from './components/Privacypolicy/Privacypolicy'
import Communityguidelines from './components/Communityguidelines/Communityguidelines'; 
import Termsofuse from './components/Termsofuse/Termsofuse';
import Partnerprogram from './components/Partnerprogram/Partnerprogram';
import Aboutus from './components/Aboutus/Aboutus'
import Partnercontact from './components/Partnerprogram/Partnercontact';
import React, { useState, useEffect } from 'react';
import Spinner from './components/Preloader/Spinner';
import DownloadApp from './components/DownloadApp/DownloadApp';
import AccountDeletionRequest from './components/AccountDeletedForm/AccountDeletionRequest';
import AccountDeletionConfirmation from './components/AccountDeletedForm/AccountDeletionConfirmation';
 
function App() {
  const [loading, setLoading] = useState(true);
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setBackgroundImageLoaded(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Set timeout for 1 second
    };
    img.src = "https://rasta-icons.s3.ap-south-1.amazonaws.com/newBgImage.svg"; // Set the source of your starting background image
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />} />
            <Route path="/Ourproducts" element={<OurProductsLayout />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/Demo" element={<Demo />} />
            <Route path="/Contact" element={<Appointment />} />
            <Route path="/Privacy-policy" element={<Privacypolicy />} />
            <Route path="/Community-guidelines" element={<Communityguidelines />} />
            <Route path="/Term-of-use" element={<Termsofuse />} />
            <Route path="/Partner-program" element={<Partnerprogram />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Partner-contact" element={<Partnercontact />} />
            <Route path="/app/rasta-global.apk" element={<DownloadApp />} />
            <Route path="/delete-account" element={<AccountDeletionRequest />} />
            <Route path="/hidden-path/confirm-account-deletion/:token" element={<AccountDeletionConfirmation />} />
          </Routes>
          <GoToTopButton />
        </Router>
      )}
    </>
  );
}

export default App;
