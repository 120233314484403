// ImpactScroller.js
import React from 'react';
import './ImpactScroller.css';

const ImpactScroller = ({ items }) => {
    return (
        <div className="impact-container">
            <div className="impact-slide-container">
                {items.map((item, index) => (
                    <div key={index} className="impact-slide-item">
                        {item}
                    </div>
                ))}
                {items.map((item, index) => (
                    <div key={index + items.length} className="impact-slide-item">
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImpactScroller;
