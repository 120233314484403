import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../apiConnection';

const AccountDeletionConfirmation = () => {
    const { token } = useParams();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const confirmDeletion = async () => {
            try {
                const response = await axios.get(`${BASE_URL}accountDelete/confirm-account-deletion/${token}`);
                setMessage('Your account has been deleted successfully.');
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error) {
                    setMessage(error.response.data.error);
                } else {
                    setMessage('An error occurred during account deletion.');
                }
            } finally {
                setLoading(false);
            }
        };

        confirmDeletion();
    }, [token]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h1 className="text-2xl font-semibold text-center text-gray-700 mb-6">Account Deletion</h1>
                <div className="text-center">
                    {loading ? (
                        <p className="text-gray-600 font-semibold">Processing...</p>
                    ) : (
                        <>
                            {message === 'Your account has been deleted successfully.' ? (
                                <div>
                                    <svg
                                        className="w-12 h-12 text-green-500 mx-auto mb-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <p className="text-green-600 font-semibold">{message}</p>
                                </div>
                            ) : (
                                <p className="text-red-600 font-semibold">{message}</p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AccountDeletionConfirmation;


