import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import foreGround from '../../../assets/fgImage2.svg';
import './HomePage.css';
import NavBar from '../Navbar/Navbar';
import Content from '../Content/Content';

gsap.registerPlugin(ScrollTrigger);

const HomePage = ({ onMenuToggle }) => {
  const fgRef = useRef(null);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = (isOpen) => {
    
    setMenuOpen(isOpen);
    
  };

  useEffect(() => {
    const fgImg = fgRef.current;
    const container = containerRef.current;
    const content = contentRef.current;

    const handleResize = () => {
      if (window.innerWidth > 1000) {
        gsap.to(fgImg, {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          ease: 'none',
          scrollTrigger: {
            trigger: container,
            start: 'top top',
            end: 'bottom top',
            scrub: true,
            pin: true,
            anticipatePin: 1,
          },
        });
      } else {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <div id="mainContainer" className="relative w-[100%]" ref={containerRef}>
        <div className="h-screen relative overflow-x-hidden">
          <div id="bgImg" className="h-screen top-0 w-full">
            <img
              src="https://rasta-icons.s3.ap-south-1.amazonaws.com/newBgImage.svg"
              alt="Background"
              className="object-cover w-screen h-full"
            />
          </div>
          <div id="fgImg" className="h-screen absolute top-0 w-full" ref={fgRef}>
            <img
              src={foreGround}
              alt="Foreground"
              className="object-cover w-screen h-full"
            />
          </div>

          <div className="h-screen w-full absolute top-0">
            <div className="nav absolute top-5 w-full">
              <NavBar onMenuToggle={handleMenuToggle} />
            </div>
            <div className={`nav absolute left-0 top-[7%] md:top-[20%] w-full h-fit pb-6 pl-10 md:pl-32 ${menuOpen ? 'opacity-20' : 'opacity-100'}`}>
              <Content />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
