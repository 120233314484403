import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import Rastagray from '../../assets/Navbar/RastaGray.png'
import Construction from '../../assets/Partnerprogram/Contruction.png'

import { FaLinkedin, FaInstagram, FaFacebook } from 'react-icons/fa';
import { AiFillTwitterCircle } from "react-icons/ai";
import { TbCircleLetterXFilled } from 'react-icons/tb';
import Footer1 from '../Footer/Footer1';

function Partnerprogram({ onMenuToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  return (

<>

<div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>
     

<div>
				
					<div className="flex flex-col md:flex-row justify-between items-start ">
						<div className='hidden md:block text-green-700 font-dmsans font-semibold mt-10 md:mt-60 ml-4 md:ml-40 text-base md:text-4xl text-center md:text-left 'style={{fontWeight:'600'}}>
              <p>Join the Rasta. Ai Partner Program</p>
              {/* <p className='ml-30'>Program</p> */}
              <p className='text-xs md:text-xl text-black mt-10 font-light '>Revolutionize road safety with Rasta AI! We're on a mission to make  </p>
              <p className='text-xs md:text-xl text-black  font-light text-justify md:text-normal mx- md:mx-0'>our cutting-edge AI-powered road monitoring technology accessible  </p>
              <p className='text-xs md:text-xl text-black  font-light text-justify md:text-normal mx- md:mx-0'>to every government agency responsible for maintaining roads worldwide.</p>
              <Link to="/Partner-contact">
              <button className="mt-10 px-4 py-2 text-white bg-green-700 rounded-xl hover:bg-green-800 font-dmsans font-semibold text-xs md:text-xl ml-10 md:ml-60 mr-10 md:mr-0 mb-4 md:mb-0"style={{fontWeight:'600'}}>
                Join Now
            </button>
            </Link>
            </div>

            <div className='block md:hidden text-green-700 font-dmsans font-semibold mt-10 md:mt-60  md:ml-40 text-base md:text-4xl text-center md:text-left 'style={{fontWeight:'600'}}>
              <p>Join the Rasta. Ai Partner Program</p>
              {/* <p className='ml-30'>Program</p> */}
              <p className='text-xs md:text-xl text-black mt-10 font-light text-center text-justify px-4'>Revolutionize road safety with Rasta AI! We're on a mission to make 
             our cutting-edge AI-powered road monitoring technology accessible
              to every government agency responsible for maintaining roads worldwide. </p>
              <Link to="/Partner-contact">
              <button className="mt-10 px-4 py-2 text-white bg-green-700 rounded-xl hover:bg-green-800 font-dmsans font-semibold text-xs md:text-xl ml-10 md:ml-60 mr-10 md:mr-0 mb-4 md:mb-0"style={{fontWeight:'600'}}>
                Join Now
            </button>
            </Link>
            </div>
           
						{/* <div classname='text-xl'>
            {/* <p>Revolutionize road safety with Rasta AI! We're on a mission to make our cutting-edge AI-powered road monitoring technology accessible to every government agency responsible for maintaining roads worldwide.
            </p> */}
            
						<div className="md:w-1/2 flex justify-center md:justify-end items-center mt-10 md:mt-  xl:mt-0 hidden md:block">
							<img
								src={Construction}
								alt="Rasta Car img"
								className="bg-cover rounded-lg md:mt-40 xl:mt-20 pb-20 md:ml-60 xl:ml-40 "
								style={{ maxWidth: '40%', maxHeight: '40%' }}
							/>
						</div>
					</div>
          </div>
				
			



    <div className="bg-gray-100 p-6 sm:p-12 md:p-20 lg:p-24 text-center">
   
    
    <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
      <h2 className="text-xl md:text-2xl font-bold text-green-700 mb-4">Become a Partner, Drive Growth, Share the Rewards:</h2>
      <p className="text-sm md:text-lg text-gray-700 mb-2">
        We're seeking passionate partners across the globe to help us spread our innovative solutions. This is your chance to:
      </p>
      <ul className="list-disc list-inside text-left text-gray-700 mb-4 mt-6 md:mt-0">
        <li className="mb-2 "><span className="font-bold">Champion Safety:</span> Play a vital role in ensuring safer roads for communities everywhere.</li>
        <li className="mb-2"><span className="font-bold">Expand Your Business:</span> Partner with a rapidly growing company and access new markets.</li>
        <li className="mb-2"><span className="font-bold">Share Revenue:</span> Benefit from a lucrative revenue-sharing model. The more solutions you deliver, the more you earn!</li>
        <li className="mb-2"><span className="font-bold">Simple Process:</span> Focus on what you do best - connecting with local governments. We handle the technical aspects.</li>
      </ul>
    </div>
    
    <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
      <h2 className="text-xl md:text-2xl font-bold text-green-700 mb-4">Who We're Looking For:</h2>
      <ul className="list-disc list-inside text-left text-gray-700 mb-4">
        <li className="mb-2"><span className="font-bold">Global Reach:</span> We seek partners with a strong presence in any government department or agency responsible for road monitoring.</li>
        <li className="mb-2"><span className="font-bold">Local Expertise:</span> Deep understanding of your region's government structure and road maintenance processes is essential.</li>
        <li className="mb-2"><span className="font-bold">Relationship Builders:</span> You'll be the bridge between Rasta AI and local or national government entities.</li>
      </ul>
    </div>
    
    <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
      <h2 className="text-xl md:text-2xl font-bold text-green-700 mb-4">Partnership Made Easy:</h2>
      <p className="text-sm md:text-lg text-gray-700 mb-2">
        We provide everything you need to succeed, including:
      </p>
      <ul className="list-disc list-inside text-left text-gray-700 mb-4 mt-6 md:mt-0">
        <li className="mb-2"><span className="font-bold">Comprehensive Training:</span> Gain in-depth knowledge of Rasta AI's technology and its value proposition.</li>
        <li className="mb-2"><span className="font-bold">Dedicated Support:</span> Our team will be there to assist you every step of the way.</li>
        <li className="mb-2"><span className="font-bold">Marketing Materials:</span> Utilize our resources to effectively showcase Rasta AI solutions to governments.</li>
      </ul>
    </div>
    
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-xl md:text-2xl font-bold text-green-700 mb-4">Together We Can:</h2>
      <ul className="list-disc list-inside text-left text-gray-700 mb-4">
        <li className="mb-2"><span className="font-bold">Revolutionize Road Monitoring:</span> Make AI technology accessible for proactive and cost-effective road maintenance globally.</li>
        <li className="mb-2"><span className="font-bold">Empower Governments:</span> Equip them with the tools they need to make data-driven decisions for safer infrastructure.</li>
        <li className="mb-2"><span className="font-bold">Build a Safer Future:</span> Contribute to a world with fewer accidents and smoother roads for everyone.</li>
      </ul>
    </div>
    
    <Link to="/Partner-contact">
              <button className="mt-10 px-4 py-2 text-white bg-green-700 rounded-xl hover:bg-green-800 font-dmsans font-semibold text-lg md:text-xl ml-0"style={{fontWeight:'600'}}>
                Join Now
            </button>
            </Link>
    
    {/* <button className="bg-blue-600 text-white py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300">
      Apply Now
    </button> */}
  </div>

  <Footer1 />
  </>
  )
}

export default Partnerprogram