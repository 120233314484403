import React, { useState } from 'react';
import { FaCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Slider from 'react-slick';
import Demo from '../../assets/demo/Demo.svg';
import './Firstpage.css';
import Rastacar from '../../assets/Rastacar/Rastacar.svg';
import { Link } from 'react-router-dom';
import Schedulemeeting from '../../assets/survey/schedulemeeting.svg';
import Presentation from '../../assets/survey/Presentation.svg';
import Survey from '../../assets/survey/Asksurvey.svg';

function Firstpage() {
 
  const carouselImages2 = [
    Schedulemeeting,
    Presentation,
    Survey
  ];

 

  
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000, 
    appendDots: dots => (
      <div>
        <ul className="slick-dots"> {dots} </ul>
      </div>
    )
  };

  return (
    <>
    <div className='overflow-x-hidden '>
      <div className="text-center mt-20 text-2xl md:text-4xl text-green-700 text-semibold font-dmsans" style={{ fontWeight: 500 }}>
        <h2>Our Products</h2>
      </div>
      <div className="text-center mt-10 font-josefin">
        <span className="text-gray-500 font-bold text-xl md:text-4xl">Rasta</span>
        <span className="text-orange-500 font-bold text-xl md:text-4xl">.360&deg; </span>
        <span className="text-green-500 font-bold text-xl md:text-4xl" style={{ color: "#00836C" }}>Enterprise</span>
        <p className="text center mt-2 text-base md:text-xl font-opensans" style={{ fontWeight: 350 }}>Detailed Road Monitoring with Cost-Effective inspections</p>
      </div>

      <div>
        <p className="font-opensans text-3xl font-medium mx-auto  md:ml-40 text-center text-left md:text-left mt-4 md:mt-0" style={{ fontWeight: 600 }}> </p>
        <div className="p-2 box-content">
          <div className="flex flex-col md:flex-row justify-between items-start ml-4 md:ml-0">
            <div className="space-y-8 md:w-1/2 ml-4 md:ml-10">
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-5 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Faster Data Processing</h3>
                  <p className='text-xs md:text-base'>Provides rapid analysis and real-time updates on road conditions.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-5 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">User-Friendly Interface</h3>
                  <p className='text-xs md:text-base'>The portal offers detailed road network analytics with one-click 360&deg; issue visualization.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-5 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">High Resolution Street View Imagery</h3>
                  <p className='text-xs md:text-base'>High-resolution street views for detailed and accurate road condition monitoring.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-7 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Historical Data Comparison</h3>
                  <p className='text-xs md:text-base'>Enables comparison with historical data to track deterioration and improvements over time.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-7 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Automated Alerts</h3>
                  <p className='text-xs md:text-base'>Sends automated alerts for critical road conditions requiring immediate attention.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-7 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Integration capabilities</h3>
                  <p className='text-xs md:text-base'>Can be integrated with existing road management systems and GIS platforms.</p>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 justify-center md:justify-end items-center mt- md:mt-10 md:mt-0">
              <img 
                src={Rastacar}
                alt="Laptop Image"
                className="w-4/5 h-auto md:w-4/4 md:ml-20 mt-10"
              />
              <Link to="/Contact">
                <button className="block mx-auto mt-6 px-6 py-3 text-white text-lg md:text-2xl rounded font-josefin" style={{ backgroundColor: '#00836C', borderRadius: '1rem', fontWeight: 600 }}>
                  Contact Sales
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="enterprise text-center mt-10 text-xl md:text-4xl text-green-700 text-semibold font-dmsans" style={{ fontWeight: 500 }}>
        <h2>Why Enterprise</h2>
      </div>
      
      <div className="md:hidden ">
      <Slider {...slickSettings}>
      <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Streamlined Operations</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                Consolidate your road network management into one platform, reducing complexity and saving time and money.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                Rasta Enterprise eliminates the need for multiple point solutions, providing a centralized hub for efficient monitoring, management, and control.
                </li>
              </ul>
            </div>
          </div>
          <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Assurance</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                Comprehensive reporting delivers detailed, reliable reports that support informed maintenance decisions quickly.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                The system is based on scientific methods and follows IRC standards, ensuring accurate, consistent, and reliable road condition assessments.
                </li>
              </ul>
            </div>
          </div>
          <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Accuracy</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                Precision detection through AI algorithms ensures accurate identification of road issues like potholes and cracks.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                Capable of identifying minor details like culverts, trees, hoardings, encroachments, signage, markings, ensuring thorough road assessments.
                </li>
              </ul>
            </div>
          </div>
</Slider>
</div>



<div className=" hidden md:flex Renegan flex justify-around items-center p-5 font-opensans font-light leading-normal " style={{ fontWeight: 350, height: '60vh' }}>
  <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
    <h2 className="text-2xl font-bold text-josefin">Streamlined Operations</h2>
    <ul className="list-disc list-inside mt-8 text-base ">
      <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
	  Consolidate your full road network management into one platform,reducing complexity and saving time and money.
      </li>
      <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
	  Rasta Enterprise eliminates the need for multiple point solutions, providing a centralized hub for efficient monitoring, management, and control.
      </li>
    </ul>
  </div>
  <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
    <h2 className="text-2xl font-bold text-josefin">Assurance</h2>
    <ul className="list-disc list-inside mt-8 text-base ">
      <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
        Comprehensive reporting delivers detailed, reliable reports that support informed maintenance decisions quickly.
      </li>
      <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
	  The system is based on scientific methods and follows IRC standards, ensuring accurate, consistent, and reliable road condition assessments.
      </li>
    </ul>
  </div>
  <div className=" table3 border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
    <h2 className="text-2xl font-bold text-josefin">Accuracy</h2>
    <ul className="list-disc list-inside mt-8 text-base ">
      <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
        Precision detection through AI algorithms ensures accurate identification of road issues like potholes and cracks.
      </li>
      <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
	  Capable of identifying minor details like culverts, trees, hoardings, encroachments, signage, markings, ensuring thorough road assessments.
      </li>
    </ul>
  </div>
</div>

<div className="askdemo block md:hidden Renegan1 text-center mt-32 md:mt-0 text-xl md:text-4xl text-green-700 font-semibold font-dmsans " style={{ fontWeight: 500 }}>
        <h2>Ask for Demo</h2>
      </div>

      <div className="mobile-carousel md:hidden text-center mt-10 relative mb-14 md:mb-0">
        <Slider {...slickSettings}>
          {carouselImages2.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Slide ${index}`} className="carousel-image px-16 " />
            </div>
          ))}
        </Slider>
      </div>

      <div className="hidden md:block Renegan1 text-center mt-10 text-2xl md:text-4xl text-green-700 font-semibold font-dmsans" style={{ fontWeight: 500 }}>
        <h2>Ask for Demo</h2>
      </div>
      {/* Conditionally render the demo image based on screen size */}
      {window.innerWidth >= 768 && (
        <div className="pb-40 text-center md:px-20">
          <img
            src={Demo}
            alt="demo image"
            className="mt-20 mx-auto"
          />
        </div>
      )}

</div>
    </>
  );
}

export default Firstpage;
