import React from 'react'
import OurNavbar from './OurNavbar'
import Firstpage from './Firstpage'
import Secondpage from './Secondpage'
import Thirdpage from './Thirdpage'
import Forthpage from './Forthpage'

function OurProductsLayout() {
  return (
    <div>
        <OurNavbar />
        <Firstpage />
        <Secondpage />
        <Thirdpage />
        <Forthpage />
        </div>
  )
}

export default OurProductsLayout