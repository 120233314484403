import React from 'react'
import Contact from '../../assets/Contact_us/Contact.gif'
import { Link } from 'react-router-dom';
import Contactus1 from '../../assets/ContactUs/Contruction.png'

function Contact_us() {
  return (
    <div className="flex flex-col md:flex-row items-center  mt-10 md:mt-10 md:px-10 ">
      <div className="flex flex-col md:bg-green-200 md:flex-row items-center">
  <div className="w-full md:w-1/2 flex justify-center md:justify-start hidden md:block mt-">
    <img 
      src={Contact}
      alt="Civil engineer lady gif" 
      className="bg-cover  ml-9  2xl:ml-12 md:ml-28" 
      style={{ maxWidth: '140%', maxHeight: '160%', objectFit: 'cover' }}
    />
  </div>
  <div className="w-full md:w-1/2 flex justify-center md:justify-start block md:hidden">
    <img 
      src={Contactus1}
      alt="Civil Engineer Lady gif" 
      className="bg-cover   " 
      style={{ maxWidth: '45%', maxHeight: '50%', objectFit: 'cover' }}
    />
  </div>
  <div className="w-full md:w-1/2 flex flex-col items-center md:items-start  xl:text-base ">
    <div className="text-center md:text-left md:mr-40 ">
      <h1 className="text-xl md:text-4xl  mb-4 font-dmsans  ml-30 md:ml-0 md:mr- mt-6 md:mt- font-bold" style={{ color: '#00836C', letterSpacing: '0.1rem' }}>
        Join Our Partner Program
      </h1>
    </div>
    <p className="font-opensans text-sm px-8 md:px-0 md:text-xl font-light mt-6 md:mr-20 text-justify " style={{fontWeight: 320}}>
      At Rasta AI, we're on a mission to revolutionize road condition 
      monitoring with accessible and affordable AI solutions. But we can't 
      achieve this alone. That's why we're launching our Partner Program! We're 
      seeking passionate individuals and organizations to join us in building a safer, 
      pothole-free future for roads around the world.
    </p>
    <div className="text-center md:text-left mt-10 md:mt-20 md:mr-60">
      <Link to="/Partner-program">
        <button className="bg-green-500 text-white text-xl md:text-2xl font-semibold py-2 px-8 rounded-xl" style={{ backgroundColor: '#00836C', fontWeight: 480 }}>
          Contact Us
        </button>
      </Link>
    </div>
  </div>
  </div>
</div>
  )
}

export default Contact_us