import React,{useState,useEffect} from 'react'

import { FaLinkedin, FaInstagram, FaFacebook } from 'react-icons/fa';
import { AiFillTwitterCircle } from "react-icons/ai";
import { Link } from 'react-router-dom';
import Footer1 from '../Footer/Footer1';
function Forthpage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
    <div className="text-center mt-2 md:mt-10 text-2xl md:text-4xl text-green-700 text-semibold font-dmsans" style={{ fontWeight: 500}}>
    <h2>Plans and Pricing</h2>
</div>

<body className="p-2 md:p-10 pb-10 md:pb-0">
  <div className="border-2 border-black p-4 rounded-2xl ">
    <div className="flex justify-between">
      <div className="w-1/3 border-r-2 border-black pr-4 text-center">
        <div className="text-center mt-8 md:mt-2 font-josefin">
          <span className="text-gray-500 font-bold text-base md:text-3xl">Rasta</span>
          <span className="text-orange-500 font-bold  text-base md:text-3xl">.AI</span>
        </div>
        <ul className="list-disc md:list-inside text-jsutify md:text-justify text-xs md:text-xl mt-12 md:mt-6 text-gray-700 font-opensans font-semibold md:font-normal">
          <li className="mt-6">AI-powered Mobile App</li>
          <li className="mt-4 md:mt-2">Pothole Portability</li>
          <li className="mt-4 md:mt-2">Comprehensive Reporting</li>
          <li className="mt-4 md:mt-2">Map Based Web Portal</li>
          <li className="mt-8 md:mt-2">Computer Vision Processing </li>
          <li className="mt-4 md:mt-2">8+ Surface Data Detection</li>
          <li className="mt-4 md:mt-2">36+ Inventory Detection</li>
          <li className="mt-8 md:mt-2">Real-Time Monitoring</li>
          <li className="mt-4 md:mt-2">Integration Capabilities</li>
          <li className="mt-4 md:mt-2">Historical Comparison</li>
          <li className="mt-4 md:mt-2">Condition Base Colour Coding</li>
        </ul>
        <div className="text-center mt-8 md:mt-6 font-josefin text-sm md:text-3xl font-semibold">
         
          <p>Per km/mile processing fees</p>
        </div>
        <Link to="/Contact">
        <button className="mt-5 bg-green-500 hover:bg-green-600 text-white font-semibold text-xs md:text-xl py-6  md:py-5 px-5 md:px-8 rounded-xl "style={{backgroundColor: "#087567"}}>Ask for Pricing</button>
        </Link>
      </div>

      <div className="w-1/3 border-r-2 border-black px-4 text-center">
      <div className="text-center mt-2 font-josefin">
        <span className={`text-gray-500 font-bold text-base md:text-3xl ${isMobile ? 'block' : ''}`}>Rasta</span>
        <span className={`text-orange-500 font-bold text-base md:text-3xl ${isMobile ? 'block' : ''}`}>.360&deg;</span>
        <span className={`text-green-500 font-bold text-base md:text-3xl ${isMobile ? 'block' : ''}`} style={{ color: "#00836C" }}>Mini</span>
      </div>
        <ul className="list-disc md:list-inside text-justify text-xs md:text-xl mt-6 text-gray-700 font-opensans font-semibold md:font-normal">
          <li className="mt-6">AI Powered Portable Kit</li>
          <li className="mt-4 md:mt-2">Pothole Probability</li>
          <li className="mt-4 md:mt-2">360&deg; Image View</li>
          <li className="mt-4 md:mt-2">User-Friendly Interface</li>
          <li className="mt-4 md:mt-2">Quick Virtual Inspection</li>
          <li className="mt-4 md:mt-2">Fast Data Processing</li>
          <li className="mt-4 md:mt-2">8+ Surface Data Detection</li>
          <li className="mt-4 md:mt-2">Comprehensive Reporting</li>
          <li className="mt-4 md:mt-2">Access Control</li>
          <li className="mt-4 md:mt-2">Historical Comparison</li>
          <li className="mt-4 md:mt-2">Bird Eye View Visualization</li>
        </ul>
        <div className="text-center mt-16 md:mt-6 font-josefin text-sm md:text-3xl font-semibold">
          
          <p>Per km/mile processing fees</p>
        </div>
        <Link to="/Contact">
        <button className="mt-5 bg-green-500 hover:bg-green-600 text-white font-semibold text-xs md:text-xl py-6  md:py-5 px-5 md:px-8 rounded-xl"style={{backgroundColor: "#087567"}}>Ask for Pricing</button>
        </Link>
      </div>

      <div className="w-1/3 pl-4 text-center">
      <div className="text-center mt-2 font-josefin">
        <span className={`text-gray-500 font-bold text-base md:text-3xl ${isMobile ? 'block' : ''}`}>Rasta</span>
        <span className={`text-orange-500 font-bold text-base md:text-3xl ${isMobile ? 'block' : ''}`}>.360&deg;</span>
        <span className={`text-green-500 font-bold text-base md:text-3xl ${isMobile ? 'block' : ''}`} style={{ color: "#00836C" }}>Enterprise</span>
      </div>
        <ul className="list-disc md:list-inside text-justify text-xs md:text-xl mt-6 text-gray-700 font-opensans font-semibold md:font-normal">
          <li className="mt-6">12K Resolution Imagery</li>
          <li className="mt-4 md:mt-2">Seasonal Monitoring</li>
          <li className="mt-4 md:mt-2">Traffic Pattern Analysis</li>
          <li className="mt-4 md:mt-2">Pothole Prediction</li>
          <li className="mt-8 md:mt-2">At a Glance View</li>
          <li className="mt-8 md:mt-2">AI-Powered Analysis</li>
          <li className="mt-4 md:mt-2">Comprehensive Reporting </li>
          <li className="mt-8 md:mt-2">User-Friendly Interface</li>
          <li className="mt-4 md:mt-2">Inventar</li>
        </ul>
        <div className="text-center mt-40 md:mt-24 font-josefin text-sm md:text-3xl font-semibold">
          
          <p>Per km/mile processing fees</p>
        </div>
        <Link to="/Contact">
        <button className="mt-5 bg-green-500 hover:bg-green-600 text-white font-semibold text-xs md:text-xl py-6  md:py-5 px-5 md:px-8 rounded-xl"style={{backgroundColor: "#087567"}}>Ask for Pricing</button>
        </Link>
      </div>
    </div>
  </div>
</body>

<div className='md:mt-10 '>
<Footer1 />
</div>

</>
  )
}

export default Forthpage