import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TbCircleLetterXFilled } from 'react-icons/tb';
import './NavbarStyle.css';

const NavBar = ({ onMenuToggle }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  return (
    <div className="text-white w-full flex justify-between items-center">
      <div className="cursor-pointer">
        <Link to="/" onClick={handleLinkClick}>
          <p className="flex items-center text-2xl ml-10 font-bold">
            Rasta
            <div className="rotating-text-container" style={{ color: '#FF6F17' }}>
              <div className="rotating-text">.360°</div>
              <div className="rotating-text">.AI</div>
              <div className="rotating-text" style={{ color: '#FF6F17' }}>.360°<span className="text-green-500">.Mini</span></div>
              <div className="rotating-text">.360</div>
              <div className="rotating-text">.AI</div>
              <div className="rotating-text">.Mini</div>
              <div className="rotating-text">.360</div>
              <div className="rotating-text">.AI</div>
              <div className="rotating-text">.Mini</div>
              <div className="rotating-text">.360</div>
              <div className="rotating-text">.AI</div>
              <div className="rotating-text">.Mini</div>
            </div>
          </p>
        </Link>
      </div>
      <ul className="hidden md:flex h-18 items-center my-auto justify-end gap-x-12 pr-12 text-white font-semibold cursor-pointer font-josefin">
        <Link to="/Ourproducts">
          <li className="rightcontentnav1 hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Our Products
          </li>
        </Link>
        <Link to="/Demo">
          <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
          </li>
        </Link>
        <Link to="/Contact">
          <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
          </li>
        </Link>
        <Link to="/Aboutus">
          <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            About us
          </li>
        </Link>
        <Link to="/Faq">
          <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
          </li>
        </Link>
      </ul>
      <button className="md:hidden mobile-menu-button text-2xl mr-5" onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" onClick={handleLinkClick} className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full">
            Home
          </Link>
          <Link to="/Ourproducts" onClick={handleLinkClick} className="text-white text-2xl border-b border-white pb-2 px-4 w-full">
            Our Products
          </Link>
          <Link to="/Demo" onClick={handleLinkClick} className="text-white text-2xl border-b border-white pb-2 px-4 w-full">
            Demo
          </Link>
          <Link to="/Contact" onClick={handleLinkClick} className="text-white text-2xl border-b border-white pb-2 px-4 w-full">
            Contact
          </Link>
          <Link to="/Aboutus" onClick={handleLinkClick} className="text-white text-2xl border-b border-white pb-2 px-4 w-full">
            About us
          </Link>
          <Link to="/Faq" onClick={handleLinkClick} className="text-white text-2xl border-b border-white pb-2 px-4 w-full">
            FAQ
          </Link>
        </div>
      )}
    </div>
  );
};

export default NavBar;
