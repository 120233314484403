import React, { useEffect, useRef } from 'react';
import ImpactScroller from './ImpactScroller';
import camera from '../../assets/defect/Laptopimage.png';
import DefectCard from './DefectCard';
import { Link } from 'react-router-dom';

const Counter = ({ value }) => {
    const counterRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) { // 'md' breakpoint
                const observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                const counter = entry.target;
                                const endValue = parseFloat(value);
                                let startValue = 0;
                                const updating = setInterval(() => {
                                    startValue += endValue / 200;
                                    counter.textContent = startValue.toFixed(0);
                                    if (startValue >= endValue) {
                                        counter.textContent = endValue;
                                        clearInterval(updating);
                                        observer.unobserve(counter);
                                    }
                                }, 10);
                            }
                        });
                    },
                    { threshold: 1 }
                );

                observer.observe(counterRef.current);

                return () => {
                    observer.disconnect();
                };
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [value]);

    return (
        <span className="counter" ref={counterRef}>
            {value}
        </span>
    );
};

const ImpactItems = [
    <>
        <div className="w-12 flex justify-center items-center text-3xl md:text-6xl text-green-700">
            <Counter value="8" /><span>K+</span>
        </div>
        <p className="text-xs md:text-sm font-semibold mt-4">Defects Detected</p>
    </>,
    <>
        <div className="w-12 flex justify-center items-center text-3xl md:text-6xl text-green-700">
            <Counter value="25" /><span>K+</span>
        </div>
        <p className="text-xs md:text-sm font-semibold mt-4">Assets Detected</p>
    </>,
    <>
        <div className="w-12 flex justify-center items-center text-3xl md:text-6xl text-green-700">
            <Counter value="5" /><span>K+</span>
        </div>
        <p className="text-xs md:text-sm font-semibold mt-4">Potholes Predicted</p>
    </>,
    <>
        <div className="w-12 flex justify-center items-center text-3xl md:text-6xl text-green-700">
            <Counter value="4" /><span>+</span>
        </div>
        <p className="text-xs md:text-sm font-semibold mt-4">Government Authorities</p>
    </>,
    <>
        <div className="w-12 flex justify-center items-center text-3xl md:text-6xl text-green-700">
            <Counter value="7" /><span>+</span>
        </div>
        <p className="text-xs md:text-sm font-semibold mt-4">Cities</p>
    </>
];

const DefectPage = () => {
    return (
        <>
            <div className="bg-Honeydew relative mt-6 md:mt-10 pt-6 md:pt-10">
                <h2 className="text-center text-2xl md:text-4xl font-dmsans mt-2 text-green-700 font-medium">
                    Our Impact
                </h2>
                <div className="md:hidden">
                    <ImpactScroller items={ImpactItems} />
                </div>
                <div className="hidden md:flex md:justify-center md:justify-evenly p-4 md:p-0 font-josefin">
                    <div className="grid grid-cols-2 gap-4 md:flex lg:gap-5 2xl:gap-20 ">
                        {ImpactItems.map((item, index) => (
                            <div key={index} className="flex flex-col justify-center items-center p-4 md:p-16">
                                {item}
                                <div className="w-full h-px bg-gray-400 mt-2"></div>
                                <p className="secondary h-1 w-full"></p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="h-[55rem]">
                <div className="flex flex-col w-4/5 mx-auto text- gap-y-8 p-5">
                    <div className="font-dmsans text-base md:text-4xl text-green-700 mt-10 font-bold" style={{ color: "#00836C" }}>
                        <p className="text-center leading-relaxed font-bold">Reliable Detection Of A Wide Range Of Defects And Assets With</p>
                        <p className="text-center font-bold">Virtual Inspections</p>
                    </div>
                    <div className="flex font-opensans text-sm text-justify md:text-base md:font-light mt-4">
                        Our solution leverages advanced computer vision technology to detect road defects, assets, and inventory with precision, tailored to meet user-specific data requirements. By harnessing data from enterprise-grade panoramic cameras and sensors, we provide accurate insights into road conditions and related details.
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-start h-auto py-12 mx-auto border-green-500 rounded-lg">
                    <div className="w-full md:w-1/2 h-full mt-0 md:mt-6 md:ml-8 flex flex-col items-center">
                        <img src={camera} alt="Camera" className="h-[14rem] md:h-[22rem] object-cover rounded-lg" />
                        <div className="moving text-center mt-4">
                            <Link to="/Ourproducts">
                                <button className="explorebutton block mx-auto mt-0 md:mt-4 ml-0 md:ml-0 px-7 md:px-8 py-2 text-white text-base md:text-xl rounded-lg md:rounded-xl" style={{ backgroundColor: '#00836C', borderRadius: '1rem' }}>Explore Now</button>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 mt-6 md:mt-0">
                        <DefectCard />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DefectPage;
