import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TbCircleLetterXFilled } from 'react-icons/tb';
import Footer1 from '../Footer/Footer1';


function Faq({ onMenuToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What is Rasta360 Enterprise?',
      answer: 'Rasta360 Enterprise is an advanced road monitoring solution offering AI-powered analysis, 12K resolution imagery, and comprehensive reporting for large-scale road networks. It provides detailed insights into road conditions and assets, including a crucial feature for predicting potholes before they occur.',
    },
    {
      question: 'How does Rasta360 Mini differ from Rasta360 Enterprise?',
      answer: 'Rasta360 Mini is a portable road monitoring solution designed for quick surveys using bikes or cars. It offers fast data processing and a user-friendly interface, making it ideal for efficient and effective road inspections.',
    },
    {
      question: 'What features does the Rasta.ai mobile app offer?',
      answer: 'The Rasta.ai mobile app offers AI-powered road issue detection, real-time data processing, and comprehensive reporting. Its user-friendly interface enables on-the-go road monitoring for any scale. The app helps gather road data with proof on a portal within a couple of hours, ensuring quick and efficient monitoring and reporting.',
    },
    {
      question: 'How quickly can I get analysis results with Rasta.ai products?',
      answer: "Rasta Ai's product range is designed for fast data processing, with the only required time being the upload and processing period, which takes a couple of hours. Once processed, the data, along with imagery, is available on the Rasta web dashboard for user viewing.",
    },
    {
      question: 'Can Rasta.ai products integrate with existing road management systems?',
      answer: 'Yes, Rasta.ai products seamlessly integrate with existing road management systems and GIS platforms, enhancing efficiency and effectiveness in road maintenance programs. Rasta provides an API for integrating the data with existing systems, ensuring smooth interoperability.',
    },
    {
      question: ' Is the Rasta360 Mini suitable for all environments?',
      answer: 'Yes, the portable and lightweight design of the Rasta360 Mini allows it to be used in various environments, making it versatile for different road monitoring needs.',
    },
    {
      question: 'What kind of issues can Rasta.ai detect?',
      answer: 'Rasta.ai utilizes advanced AI algorithms to detect a variety of road issues, including surface defects and assets. It can identify over 8+ types of surface defects and 48+ types of assets, ensuring thorough and accurate assessments of road conditions.',
    },
    {
      question: 'How often should I use Rasta360 Enterprise for monitoring?',
      answer: 'We recommend using Rasta360 Enterprise for seasonal monitoring, including pre-monsoon, post-monsoon, and post-maintenance surveys. This ensures continuous road quality monitoring. The game-changer feature of identifying probable pothole locations helps authorities take early remedial action, making it ideal for on-demand road maintenance.',
    },
    {
      question: ' Is my personal information kept private on Rasta social?',
      answer: 'Yes, your personal information is protected and only used to facilitate communication about your reports. It is only shared with government not with any other third parties.',
    },
    {
      question: 'How can I participate?',
      answer: 'To participate, download the Rasta Social App from your app store, create an account, and start capturing and reporting potholes in your area. Your contributions help improve road safety.',
    },
    {
      question: 'Is there any correspondence or connection with the government?',
      answer: 'Yes, the app is directly connected to government systems, ensuring that your reports are sent to the appropriate local authorities for prompt action.',
    },
    {
      question: 'Is Rasta  application is safe for use?',
      answer: 'Yes, the rasta application are totally follows the compliance rules and regulations.',
    },
    {
      question: ' In which countries does Rasta.ai operate?',
      answer: 'Rasta.ai supports operations in all countries, making it a global solution for road condition monitoring and reporting.',
    },
    {
      question: 'How to buy Rasta services?',
      answer: 'To buy Rasta services, visit our website and navigate to the "Services" section. Choose the service package that fits your needs and follow the instructions for purchase. For enterprise solutions, contact our sales team directly through the provided contact form.',
    },
    {
      question: 'Is there any customization available in products?',
      answer: 'We offer large-scale enterprise solutions and can customize the portal and processes exclusively for enterprises.',
    },
    
  ];

  return (
    <>
      <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start"style={{ color: '#00836C'}}>
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>

      <div className="text-center mt-20 md:mt-40 text-xl md:text-4xl text-green-700 text-semibold font-dmsans" style={{ fontWeight: 500 }}>
        <h2>FAQ</h2>
        <h2 className="text-center text-gray-500 text-semibold font-opensans mt-2" style={{ fontWeight: 500 }}>
          Frequently asked questions
        </h2>
      </div>

      <div className="py-20  mx-auto font-opensans">
        {faqs.map((faq, index) => (
          <div key={index} className="mb-6 w-11/12 mx-auto">
            <button
              className="text-left flex justify-between w-full bg-green-300 p-2 md:p-6 rounded-t-xl text-sm md:text-base font-semibold focus:outline-none"
              onClick={() => handleToggle(index)}
            >
              <span>{faq.question}</span>
              <svg
                className={`w-4 h-4 md:w-8 md:h-8 transform transition-transform duration-200 ${
                  activeIndex === index ? 'rotate-180' : ''
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {activeIndex === index && (
              <div
                className="p-6 bg-green-100 text-sm md:text-xl transition-all duration-300 rounded-b-xl w-full"
                style={{ maxHeight: '500px', overflow: 'hidden' }}
              >
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>

     <Footer1 />

    </>
  );
}

export default Faq;
