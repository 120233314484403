import React from 'react'
import RastaProduct from "../../assets/DownloadApp/rastaai-product.png"

const DownloadApp = () => {

  return (
    <div className='md:h-screen bg-black flex items-center justify-center gap-15 flex-col md:flex-row py-10'>
        <img src={RastaProduct} alt="rasta mobile app" className='w-[450px]'/>
        <div className='flex flex-col items-center justify-center'>
        <div className='flex gap-5 items-center flex-col'>
        <div className='text-3xl md:text-6xl font-bold text-[#FF6100] text-center'>Download Our App</div>
        <a href="https://rasta-icons.s3.ap-south-1.amazonaws.com/apk/rasta-global.apk" download>
            <button className='px-6 py-3 bg-[#087567] rounded-xl font-semibold text-white ' >Download APK</button>
        </a>
        </div>
        </div>
      
    </div>
  )
}

export default DownloadApp
