import React from 'react';
import RoadImage from '../../assets/Road/RoadImage.svg';
import Mobilebridge from '../../assets/Road/mobilebridge.svg';
import './Bridge.css';

function Bridge() {
  return (
    <div className="column flex flex-col mt-20 mb-0 md:mb-0 md:mt-0">
      {/* Text Section */}
      <div className="text-center font-dmsans text-lg md:text-4xl font-bold px-4 md:px-0 my-2 mb-4 mt-40 md:mt-12" style={{ letterSpacing: '0.1rem', color: '#00836C' }}>
        Visualize for Better Understanding, Better Decision-Making
      </div>

      {/* Image Section */}
      <div className="flex-">
        {/* Mobile Image */}
        <img
          src={Mobilebridge}
          alt="Mobile Bridge img"
          className="w-full h-full object-cover mt-4 md:hidden" // Hidden on medium screens and above
        />

        {/* Desktop Image */}
        <img
          src={RoadImage}
          alt="Road img"
          className="w-full h-full object-cover mt-4 hidden md:block" // Hidden on small screens, shown on medium screens and above
        />
      </div>
    </div>
  );
}

export default Bridge;
