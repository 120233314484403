import React from 'react'
//import Partners from '../../assets/clients/partners.png'
import Pwd from '../../assets/clients/Pwd.png';
import Maharaj from '../../assets/clients/Maharaj.png';
import Snake from '../../assets/clients/Snake.png';
import Lotus from '../../assets/clients/Lotus.png';
import Msrdc from '../../assets/clients/Msrdc.png';
import Samruddhi from '../../assets/clients/Samruddhi.png';
import './OurPartners.css';

const OurClients = () => {
  return (
    <div>
    <div className="w-full h-[40vh] md:h-[35vh] flex justify-evenly items-center flex-col ">
        <div className="w-[80%]  font-semibold text-xl md:text-4xl flex justify-center items-center   font-dmsans" style={{fontWeight: 600, color:"#00836C"}} >
          Our Partners
        </div>
       
       <div className="container">
           <div className="slide-container">
              
               <div className="slide-image">
                  <img 
                  src={Pwd}
                  alt=" Pwd Icon"
                  />
               </div>
              
               <div className="slide-image">
                  <img 
                  src={Maharaj}
                  alt=" pune municipalty Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Snake}
                  alt=" nagpur minicipalty Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Lotus}
                  alt=" niskarsh jan seva Icon"
                  />
               </div>

               <div className="slide-image ">
                  <img 
                  src={Msrdc}
                  alt=" msrdc Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Samruddhi}
                  alt=" samruddhi mahamarg Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Pwd}
                  alt=" Pwd Icon"
                  />
               </div>
              
               <div className="slide-image">
                  <img 
                  src={Maharaj}
                  alt="  pune municipalty Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Snake}
                  alt="nagpur minicipalty Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Lotus}
                  alt=" niskarsh jan seva Icon"
                  />
               </div>

               <div className="slide-image ">
                  <img 
                  src={Msrdc}
                  alt="  msrdc Icon"
                  />
               </div>

               <div className="slide-image">
                  <img 
                  src={Samruddhi}
                  alt="samruddhi mahamarg Icon"
                  />
               </div>

               

               

               


           </div>


       </div>
        
      </div>
      </div>
  )
}

export default OurClients
 