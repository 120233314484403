import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import { TbCircleLetterXFilled } from 'react-icons/tb';
import Footer1 from '../Footer/Footer1';


function Privacypolicy({ onMenuToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  return (
	<>
 <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>

<div className="bg-Honeydew relative pt-4 pb-8 mt-20">
			<h2 className="text-center text-2xl md:text-4xl font-josefin mt-5 text-green-800 font-medium">
				Privacy Policy
			</h2>
			<h2 className="text-center text-base md:text-xl font-josefin mt-5 text-green-800 font-medium">
			for rasta-Ai.com / rasta360.com
			</h2>
			<h2 className="text-center text-base md:text-xl font-josefin mt-5 text-green-800 font-medium">
			Effective Date: 13/06/2024
			</h2>
			</div>

			
			<div className="p-8 px-8 md:px-40 text-justify md:text-normal bg-gray-100 font-josefin">
      
      <section>
        <h2 className="text-base font-semibold mb-2 mt-10">1. Introduction</h2>
        <p className="text-sm font-light"style={{fontWeight: 350}}>
          Welcome to rasta-Ai.com / rasta360.com, a website owned and operated by Ai Unika Technologies (P) Limited. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. Our services include AI-based road network monitoring SAAS and PAAS products, street-view level geospatial data collection, and analysis services for road condition and asset monitoring. We are committed to protecting your privacy and ensuring the security of your personal data.
        </p>
        <p className="text-sm font-light"style={{fontWeight: 350}}>
          By using our services, you agree to the terms outlined in this Privacy Policy. If you do not agree with the terms, please do not use our services.
        </p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">2. Information We Collect</h2>

        <h3 className="text-sm font-semibold mb-2 mt-8">2.1 Personal Information</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Name: To personalize your experience and for account identification.</li>
          <li>Email Address: For communication purposes, including sending updates and responding to inquiries.</li>
          <li>Phone Number: To contact you for support, updates, and verification.</li>
          <li>Payment Information: To process transactions securely.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">2.2 Device Information</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Device Type and Model: To optimize our services for different devices.</li>
          <li>Operating System: To ensure compatibility and improve user experience.</li>
          <li>Unique Device Identifiers: For security and to prevent fraudulent activity.</li>
          <li>Mobile Network Information: To understand connectivity issues and provide better service.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">2.3 Location Information</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Live Location: For real-time monitoring and service provision.</li>
          <li>GPS Data: To enhance the accuracy of our services.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">2.4 Usage Data</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Pages Viewed: To understand user preferences and improve our content.</li>
          <li>Links Clicked: To analyze and optimize our service offerings.</li>
          <li>Time Spent on Pages: To assess engagement and improve user experience.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">2.5 Additional Information</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>ID Proof: For verification and security purposes.</li>
          <li>Designation: To tailor our services to your professional needs.</li>
          <li>Work Organization: To understand your professional context and offer relevant services.</li>
          <li>Work ID Card: For identification and access to certain services.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">2.6 Access to Phone and 360 Camera Data</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Phone Data: Information stored on your phone that may be necessary for our services.</li>
          <li>360 Camera Data: Images and videos captured using 360 cameras for comprehensive road condition monitoring.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">2.7 Data Collection Devices</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Mobile Cameras: To capture images and videos for road condition monitoring.</li>
          <li>360 Degree Cameras: For comprehensive visual data collection.</li>
          <li>Drone Cameras: For aerial data collection and analysis.</li>
          <li>Meta Data: Collecting metadata associated with images, videos, and other data.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">2.8 Data Responsibility</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>
          We do not have knowledge of whether the data collection is occurring in restricted or sensitive areas. The responsibility for ensuring that data collection complies with local regulations and restrictions lies entirely with the field personnel or authority conducting the survey. Ai Unika Technologies (P) Limited is not liable for data collection in restricted places.
        </p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">3. How We Use Your Information</h2>

        <h3 className="text-sm font-semibold mb-2 mt-8">3.1 To Provide and Improve Our Services</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Maintain and Enhance Services: Ensure the smooth operation of our services and improve them based on user feedback.</li>
          <li>Personalize Your Experience: Tailor our services to meet your preferences and needs.</li>
          <li>Develop New Features and Products: Innovate and expand our offerings to better serve you.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">3.2 Communication</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Send Updates and Marketing Materials: Keep you informed about our services, new features, and promotional offers.</li>
          <li>Respond to Inquiries: Provide support and answer any questions you may have.</li>
          <li>Customer Support: Assist you with any issues and improve your overall experience.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">3.3 Analytics</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Analyze Usage Patterns: Understand how users interact with our services to improve functionality.</li>
          <li>Perform Market Research: Gather insights to better understand our market and inform our business strategies.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">4. Sharing Your Information</h2>

        <h3 className="text-sm font-semibold mb-2 mt-8">4.1 With Service Providers</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Facilitate Our Services: Ensure the efficient operation of our services.</li>
          <li>Provide Customer Support: Offer timely and effective assistance.</li>
          <li>Perform Analytics and Market Research: Gain insights and enhance our offerings.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">4.2 Legal Requirements</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Compliance with Legal Obligations: Adhere to laws, regulations, and legal processes.</li>
          <li>Protecting Rights and Safety: Safeguard our rights, privacy, safety, or property, and that of our users or the public.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">4.3 Business Transfers</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>
          In the event of a merger, acquisition, or asset sale, your information may be transferred. We will notify you before your information is transferred and becomes subject to a different privacy policy.
        </p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">5. Data Retention</h2>
        <p className="text-sm font-light"style={{fontWeight: 350}}>
          We retain your personal information for as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations. This includes:
        </p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Operational Purposes: Ensuring the continuity and quality of our services.</li>
          <li>Legal and Regulatory Compliance: Adhering to applicable laws and regulations.</li>
          <li>Business and Analytical Needs: Supporting business operations and improving our services.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">6. Security</h2>
        <p className="text-sm font-light"style={{fontWeight: 350}}>
          We implement security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. These measures include:
        </p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Encryption: Securing data transmission and storage.</li>
          <li>Access Controls: Restricting access to authorized personnel only.</li>
          <li>Regular Audits: Conducting security audits and assessments.</li>
        </ul>
        <p className="text-sm font-light"style={{fontWeight: 350}}>
          However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.
        </p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">7. Your Rights</h2>

        <h3 className="text-sm font-semibold mb-2 mt-8">7.1 Access and Update</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>You have the right to access and update your personal information. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Reviewing Your Information: Checking the personal data we hold about you.</li>
          <li>Updating Your Details: Correcting any inaccuracies in your information.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2">7.2 Deletion</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>You can request the deletion of your personal information, subject to certain legal obligations. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Erasure Requests: Asking us to delete your personal data.</li>
          <li>Legal Compliance: Retaining information as required by law.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">7.3 Opt-Out</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>You can opt-out of receiving marketing communications from us by following the unsubscribe instructions provided in the emails. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Email Subscriptions: Managing your preferences for receiving marketing emails.</li>
          <li>Promotional Offers: Opting out of receiving promotional materials.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">8. Cookies and Tracking Technologies</h2>

        <h3 className="text-sm font-semibold mb-2 mt-8">8.1 Cookies</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We use cookies and similar tracking technologies to track activity on our service and hold certain information. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Session Cookies: Enabling essential functions of the website.</li>
          <li>Persistent Cookies: Remembering your preferences and improving user experience.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">8.2 Types of Cookies</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Essential Cookies: Necessary for the operation of our website.</li>
          <li>Analytical/Performance Cookies: Help us understand how our website is used and improve its performance.</li>
          <li>Functionality Cookies: Remember your preferences and enhance your experience.</li>
          <li>Targeting Cookies: Used to deliver relevant advertisements and track ad performance.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">8.3 Managing Cookies</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our services. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Browser Settings: Adjusting your browser settings to manage cookie preferences.</li>
          <li>Cookie Preferences: Using our website’s cookie management tools to control cookie usage.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">9. Third-Party Services</h2>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Our services may contain links to third-party websites and services. We are not responsible for the privacy practices or content of these third-party sites. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>External Links: Accessing third-party websites through links on our site.</li>
          <li>Third-Party Integrations: Using third-party services integrated with our platform.</li>
        </ul>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We recommend reviewing the privacy policies of these third-party websites and services.</p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">10. Children's Privacy</h2>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Parental Consent: Ensuring that we do not collect information from children without parental consent.</li>
          <li>Data Deletion: Removing any personal data of children under 13 from our records.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">11. International Data Protection</h2>
        <p  className="text-sm font-light"style={{fontWeight: 350}}>We comply with international data protection regulations, including the General Data Protection Regulation (GDPR) for users in the European Union and data protection regulations in the American region. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>GDPR Compliance: Adhering to GDPR principles for data protection and privacy.</li>
          <li>American Data Protection Laws: Following relevant data protection regulations in the United States.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">12. Incidents, Accidents, and Malpractice</h2>

        <h3 className="text-sm font-semibold mb-2">12.1 Responsibility</h3>
        <p  className="text-sm font-light"style={{fontWeight: 350}}>Ai Unika Technologies (P) Limited will not be responsible for any incidents, accidents, fraud, or malpractice that occur due to the actions of our employees, interns, channel partners, vendors, third parties, cyber attackers, hackers, or fraudulent individuals. The responsibility for such actions lies solely with the respective individuals involved. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Employee Actions: Incidents caused by employees or interns.</li>
          <li>Third-Party Actions: Malpractice by channel partners, vendors, or other third parties.</li>
          <li>Cybersecurity Incidents: Fraud or attacks by cyber attackers or hackers.</li>
          <li>Individual Responsibility: Holding individuals accountable for their own actions.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">12.2 Liability</h3>
        <p  className="text-sm font-light"style={{fontWeight: 350}}>Ai Unika Technologies (P) Limited is not liable for any data breaches, unauthorized access, or any other incidents caused by the aforementioned parties. The liability rests with the individuals or entities responsible for the actions.</p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-2  mt-10">13. Changes to This Privacy Policy</h2>
        <p  className="text-sm font-light"style={{fontWeight: 350}}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Policy Updates: Making periodic changes to reflect new practices or legal requirements.</li>
          <li>User Notification: Informing you of significant changes to our Privacy Policy.</li>
        </ul>
        <p  className="text-sm font-light"style={{fontWeight: 350}}>We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
      </section>

	  <section>
        <h2 className="text-base font-semibold mb-2  mt-10">Appendix: Detailed Data Collection Practices</h2>

        <h3 className="text-sm font-semibold mb-2  mt-8">A. Data Collection Methods</h3>

        <h4 className="text-sm font-semibold mb-2">A.1 Automated Data Collection</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We use automated methods, such as sensors and cameras, to collect data on road conditions and assets. This data includes images, videos, and sensor readings. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Sensor Data: Gathering information from sensors for accurate road condition monitoring.</li>
          <li>Camera Data: Capturing images and videos for visual analysis.</li>
        </ul>

        <h4 className="text-sm font-semibold mb-2">A.2 User-Provided Data</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Users may provide data through:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Uploading Images or Videos: Contributing visual data for analysis.</li>
          <li>Submitting Feedback or Reports: Providing insights and reports on road conditions.</li>
        </ul>

        <h4 className="text-sm font-semibold mb-2">A.3 Access to Phone and 360 Camera Data</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We access the entire phone and 360 camera data, including:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Phone Data: Information stored on your phone that may be necessary for our services.</li>
          <li>360 Camera Data: Images and videos captured using 360 cameras for comprehensive road condition monitoring.</li>
        </ul>

        <h4 className="text-sm font-semibold mb-2">A.4 Location Data Responsibility</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>With the location data collected, we do not have knowledge of whether the data collection is occurring in restricted or sensitive areas. The responsibility for ensuring that data collection complies with local regulations and restrictions lies entirely with the field personnel or authority conducting the survey. Ai Unika Technologies (P) Limited is not liable for data collection in restricted places. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Field Responsibility: Ensuring compliance with local laws and regulations during data collection.</li>
          <li>No Liability: Acknowledging that Ai Unika Technologies (P) Limited is not responsible for unauthorized data collection in restricted or sensitive areas.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">B. Data Use Details</h3>

        <h4 className="text-sm font-semibold mb-2">B.1 Improving Road Safety</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Collected data is analyzed to identify and address road safety issues. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Safety Analysis: Examining data to detect potential hazards and improve safety measures.</li>
          <li>Preventive Measures: Implementing strategies to mitigate identified risks.</li>
        </ul>

        <h4 className="text-sm font-semibold mb-2">B.2 Infrastructure Planning</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We use data to assist in urban planning and infrastructure development. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Urban Development: Supporting the planning and development of road infrastructure.</li>
          <li>Asset Management: Assisting in the maintenance and management of road assets.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">C. Data Sharing and Monetization</h3>

        <h4 className="text-sm font-semibold mb-2">C.1 Anonymized Data</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We may share anonymized data with third parties for research and development purposes. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Research Collaboration: Working with research institutions to improve our services.</li>
          <li>Data Insights: Providing anonymized data to enhance understanding and innovation.</li>
        </ul>

        <h4 className="text-base font-semibold mb-2">C.2 Commercial Use</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We reserve the right to use collected data for commercial purposes, including selling anonymized data to third parties. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Data Licensing: Offering data insights to third parties for commercial applications.</li>
          <li>Market Analysis: Using data to inform market strategies and business decisions.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">D. User Control and Consent</h3>

        <h4 className="text-sm font-semibold mb-2">D.1 Data Access</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Users can request access to their data at any time. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Information Requests: Asking for details about the data we hold about you.</li>
          <li>Data Copies: Requesting copies of your personal data.</li>
        </ul>

        <h4 className="text-base font-semibold mb-2">D.2 Withdrawal of Consent</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Users can withdraw consent for data collection by contacting us. This may limit the functionality of our services. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Consent Withdrawal: Stopping the collection and processing of your data.</li>
          <li>Service Impact: Understanding the potential impact on service availability and performance.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">E. Legal Compliance and Dispute Resolution</h3>

        <h4 className="text-sm font-semibold mb-2">E.1 Compliance with Laws</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We comply with applicable data protection laws and regulations, including the Information Technology Act, 2000, and the Rules thereunder, as well as the GDPR and relevant regulations in the American region. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>IT Act Compliance: Adhering to Indian data protection laws and standards.</li>
          <li>GDPR Compliance: Following GDPR principles and requirements for data protection.</li>
          <li>American Data Protection Laws: Ensuring compliance with U.S. data protection regulations.</li>
        </ul>

        <h4 className="text-base font-semibold mb-2">E.2 Judicial Matters</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>All judicial matters arising from this Privacy Policy will be resolved exclusively in the courts of Pune, India, and will be limited to Indian jurisdiction. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Jurisdiction: Agreeing that any legal disputes will be handled in Pune, India.</li>
          <li>Legal Proceedings: Understanding that legal matters are subject to Indian laws.</li>
        </ul>

        <h4 className="text-base font-semibold mb-2">E.3 Dispute Resolution</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Any disputes arising from this Privacy Policy will be resolved through arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Arbitration Agreement: Agreeing to resolve disputes through arbitration.</li>
          <li>Arbitration Process: Following the procedures outlined in the Arbitration and Conciliation Act.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">F. Additional Information for International Users</h3>

        <h4 className="text-sm font-semibold mb-2">F.1 Data Transfer</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Your information may be transferred to—and maintained on—servers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. This includes:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>International Transfers: Understanding that data may be stored and processed in different countries.</li>
          <li>Data Protection: Ensuring that your data is protected according to applicable laws and standards.</li>
        </ul>

        <h4 className="text-sm font-semibold mb-2">F.2 Compliance with Global and Regional Standards</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>In addition to compliance with Indian and American data protection laws, Ai Unika Technologies (P) Limited also adheres to global best practices and industry standards to ensure data privacy and protection. These include, but are not limited to:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Global Intellectual Property Act: Adhering to global standards and best practices related to intellectual property rights, including patents, trademarks, and copyrights.</li>
          <li>International Highway Engineering Codes: Following globally recognized guidelines and standards in highway engineering and road infrastructure development.</li>
          <li>Research Methodologies and Practices: Implementing global best practices in research methodologies related to road condition monitoring and asset management.</li>
        </ul>

        <h4 className="text-sm font-semibold mb-2">F.3 Supplementary Compliance Frameworks</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We align our data protection practices with additional global compliance frameworks, including:</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>General Data Protection Regulation (GDPR): Ensuring compliance with the GDPR principles and requirements when dealing with data subjects in the European Union.</li>
          <li>American Data Protection Laws: Following relevant data protection regulations in the United States, including the California Consumer Privacy Act (CCPA) where applicable.</li>
          <li>Global Compliance Initiatives: Engaging in global data protection compliance initiatives to ensure alignment with international standards.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">G. Additional Clauses for Specialized Sectors</h3>

        <h4 className="text-sm font-semibold mb-2">G.1 Highway Engineering and Road Condition Monitoring</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Methodologies: Adhering to industry-standard methodologies and codes for highway engineering, road design, and road condition monitoring.</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Data Use: Ensuring that the data collected for road condition monitoring is utilized solely for infrastructure development, maintenance, and enhancement purposes.</li>
        </ul>

        <h4 className="text-sm font-semibold mb-2">G.2 Research and Innovation</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Research Data: Collecting and utilizing research data strictly for analytical purposes to improve road infrastructure and asset monitoring capabilities.</p>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Innovative Practices: Implementing innovative practices and new technologies in road condition monitoring while maintaining data privacy and security.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">H. Data Retention Policy</h3>

        <h4 className="text-sm font-semibold mb-2">H.1 Retention Periods</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Personal information will be retained for as long as necessary to fulfil the purposes for which it was collected or as required by law.</p>

        <h4 className="text-sm font-semibold mb-2">H.2 Data Disposal</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We ensure secure disposal of personal data that is no longer needed.</p>

        <h3 className="text-base font-semibold mb-2  mt-10">I. Privacy Impact Assessments</h3>

        <h4 className="text-sm font-semibold mb-2">I.1 Impact Assessments</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>We conduct regular privacy impact assessments to evaluate the impact of new technologies and practices on user privacy.</p>

        <h4 className="text-sm font-semibold mb-2">I.2 Stakeholder Involvement</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Engaging stakeholders in assessing privacy implications helps ensure that user privacy is prioritized.</p>

        <h3 className="text-2base font-semibold mb-2  mt-10">J. Miscellaneous Clauses</h3>

        <h4 className="text-sm font-semibold mb-2">J.1 Severability</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>If any provision of this Privacy Policy is found to be invalid or unenforceable, the remainder shall continue to be valid and enforceable.</p>

        <h4 className="text-sm font-semibold mb-2">J.2 Interpretation</h4>
        <p className="text-sm font-light"style={{fontWeight: 350}}>This Privacy Policy will be interpreted in accordance with applicable laws and regulations.</p>
      </section>

	  <section>
        <h2 className="text-base font-semibold mb-2  mt-10">Additional Clauses for Specialized Sectors</h2>

        <h3 className="text-base font-semibold mb-2  mt-10">K. Highway Engineering and Road Condition Monitoring</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Methodologies: Adhering to industry-standard methodologies and codes for highway engineering, road design, and road condition monitoring.</li>
          <li>Data Use: Ensuring that the data collected for road condition monitoring is utilized solely for infrastructure development, maintenance, and enhancement purposes.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">L. Research and Innovation</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Research Data: Collecting and utilizing research data strictly for analytical purposes to improve road infrastructure and asset monitoring capabilities.</li>
          <li>Innovative Practices: Implementing innovative practices and new technologies in road condition monitoring while maintaining data privacy and security.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">M. Data Retention Policy</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Retention Periods: Personal information will be retained for as long as necessary to fulfil the purposes for which it was collected or as required by law.</li>
          <li>Data Disposal: We ensure secure disposal of personal data that is no longer needed.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">N. Data Retention Policy</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Retention Periods: Personal information will be retained for as long as necessary to fulfil the purposes for which it was collected or as required by law.</li>
          <li>Data Disposal: We ensure secure disposal of personal data that is no longer needed.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">O. Privacy Impact Assessments</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Impact Assessments: We conduct regular privacy impact assessments to evaluate the impact of new technologies and practices on user privacy.</li>
          <li>Stakeholder Involvement: Engaging stakeholders in assessing privacy implications helps ensure that user privacy is prioritized.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">P. Miscellaneous Clauses</h3>
        <ul className="list-disc pl-5 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Severability: If any provision of this Privacy Policy is found to be invalid or unenforceable, the remainder shall continue to be valid and enforceable.</li>
          <li>Interpretation: This Privacy Policy will be interpreted in accordance with applicable laws and regulations.</li>
        </ul>

        <h3 className="text-base font-semibold mb-2  mt-10">Y. Contact Information</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>For any questions, concerns, or requests regarding your data or this Privacy Policy, please contact us at:</p>
        <address className="not-italic mb-4 text-sm font-light"style={{fontWeight: 350}}>
          Ai Unika Technologies (P) Limited<br />
          903, Rajiv Gandhi Infotech Park, Hinjewadi, Phase 2, Pune (MH) India -57<br />
          Email: <a href="mailto:support@rasta-ai.com" className="text-blue-600 underline">support@rasta-ai.com</a>
        </address>

        <h3 className="text-base font-semibold mb-2  mt-10">AA. Review and Acknowledgment</h3>
        <p className="text-sm font-light"style={{fontWeight: 350}}>By using rasta-Ai.com and our services, you confirm that you have read, understood, and agree to the terms of this Privacy Policy.</p>
        <p className="text-sm font-light"style={{fontWeight: 350}}>Effective Date: 13/06/2024</p>
      </section>
      
     
    </div>
   <Footer1 />
</>
  )
}

export default Privacypolicy