import React from 'react';
import './ContentStyle.css';
import { Typewriter } from 'react-simple-typewriter';
import { TbCircleLetterXFilled } from 'react-icons/tb';
import { Link } from 'react-router-dom';

const Content = ({ menuOpen }) => {
  return (
    <div className={`text-white flex flex-col gap-y-0 md:gap-y-6 ${menuOpen ? 'opacity-20 block' : 'opacity-100'}`}>
      <div className="heading1 slide-bottom font-bold text-left font-montserrat font-extrabold">
        <p className="text-xl md:text-4xl">
          Welcome To Rasta<span className="span1 text-orange-500">.Ai</span>
        </p>
      </div>
      <div className="wrapper">
        <div className="font-bold font-montserrat text-5xl mt-20" style={{ fontWeight: 700 }}>
          <p className="text-lg md:text-4xl">Monitor Your Road Network</p>
          <p className="text-lg md:text-4xl">For
            <span className="ml-4 text-green-500 text-lg md:text-4xl" style={{ color: '#FF6F17' }}>
              <Typewriter
                words={['Better Performance', 'Better Safety', 'Pothole Free Roads']}
                loop={0}
                cursor
                cursorStyle="_"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </p>
          <p className="contenthide1 text-2xl mt-10 font-montserrat" style={{ fontWeight: 600 }}>Effortless Virtual Inspections, Early Problem</p>
          <p className="contenthide2 text-2xl font-montserrat" style={{ fontWeight: 600 }}>Detection, Cost And Time Savings.</p>
          <p className="contenthide3 text-lg md:text-2xl mt-80 font-montserrat md:mt-8" style={{ fontWeight: 700 }}>Keep Roads Pothole-Free with Advanced Monitoring</p>
          <div className="mt-8 flex space-x-0 md:space-x-12">
            <Link to="https://rastaai.in/">
              <button className="hidden md:block bg-orange-500 text-white px-6 py-3 rounded-xl text-base font-josefin cursor-pointer" style={{ fontWeight: 600 }}>Enterprise Login</button>
            </Link>
            <Link to="/Demo">
              <button className="block bg-orange-500 text-white px-5 md:px-6 py-2 md:py-3 rounded-xl md:rounded-xl text-sm md:text-base font-josefin cursor-pointer" style={{ fontWeight: 600 }}>Request Demo</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
