import React, { useEffect, useState } from 'react';
import './map.css';
import MapImage1 from '../../assets/Map/map_icon1.png';
import Dot1 from '../../assets/Map/dot_1.png';
import Dot2 from '../../assets/Map/dot_2.png';
import Dot3 from '../../assets/Map/dot_3.png';
import Dot4 from '../../assets/Map/dot_4.png';
import Dot5 from '../../assets/Map/dot_5.png';
import Dot6 from '../../assets/Map/dot_6.png';
import Dot7 from '../../assets/Map/dot_7.png';
import Dot8 from '../../assets/Map/dot_8.png';
import Dash_1 from '../../assets/Map/dash_1.png';
import Dash_2 from '../../assets/Map/dash_2.png';
import Dash_3 from '../../assets/Map/dash_3.png';
import Dash_4 from '../../assets/Map/dash_4.png';
import Dash_5 from '../../assets/Map/dash_5.png';
import Dash_6 from '../../assets/Map/dash_6.png';
import Dash_7 from '../../assets/Map/dash_7.png';
import Dash_8 from '../../assets/Map/dash_8.png';
import './fallingDots.css';
import MapImage from '../../assets/secondPage/Map.png';
import Mobilemap from '../../assets/Map/map.svg';

const Map = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [dotAnimationFinished, setDotAnimationFinished] = useState(false);

  const iconData = [
    { type: 'image', src: MapImage, className: 'map_icon2' },
    { type: 'image', src: Dot1, className: 'dot_icon1' },
    { type: 'image', src: Dot2, className: 'dot_icon2' },
    { type: 'image', src: Dot3, className: 'dot_icon3' },
    { type: 'image', src: Dot4, className: 'dot_icon4' },
    { type: 'image', src: Dot5, className: 'dot_icon5' },
    { type: 'image', src: Dot6, className: 'dot_icon6' },
    { type: 'image', src: Dot7, className: 'dot_icon7' },
    { type: 'image', src: Dot8, className: 'dot_icon8' },
    { type: 'image', src: Dash_1, className: 'dash_icon1' },
    { type: 'text', text: 'Ravelling', className: 'falling-text1A' },
    { type: 'text', text: 'Nehru Science Center Road', className: 'falling-text1B' },
    { type: 'image', src: Dash_2, className: 'dash_icon2' },
    { type: 'text', text: 'Manhole', className: 'falling-text2A' },
    { type: 'text', text: 'Worli Hill Road', className: 'falling-text2B' },
    { type: 'image', src: Dash_3, className: 'dash_icon3' },
    { type: 'text', text: 'Major pothole & Crack', className: 'falling-text3A' },
    { type: 'text', text: 'Grant Road', className: 'falling-text3B' },
    { type: 'image', src: Dash_4, className: 'dash_icon4' },
    { type: 'text', text: 'Damaged Zebra Crossing', className: 'falling-text4A' },
    { type: 'text', text: 'Dr Homi Bhabha Rd', className: 'falling-text4B' },
    { type: 'image', src: Dash_5, className: 'dash_icon5' },
    { type: 'text', text: 'Bus Stop', className: 'falling-text5A' },
    { type: 'text', text: 'Mahapalika Marg', className: 'falling-text5B' },
    { type: 'image', src: Dash_6, className: 'dash_icon6' },
    { type: 'text', text: 'Pothole', className: 'falling-text6A' },
    { type: 'text', text: 'Reynolds Road', className: 'falling-text6B' },
    { type: 'image', src: Dash_7, className: 'dash_icon7' },
    { type: 'text', text: 'Bleeding', className: 'falling-text7A' },
    { type: 'text', text: 'Forsberry Road', className: 'falling-text7B' },
    { type: 'image', src: Dash_8, className: 'dash_icon8' },
    { type: 'text', text: 'Damaged Sign Boards', className: 'falling-text8A' },
    { type: 'text', text: 'Eastern Express Hwy', className: 'falling-text8B' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const mapPosition = document.getElementById('map').offsetTop;
      const mapHeight = document.getElementById('map').offsetHeight;
      const scrollThreshold = mapPosition + (mapHeight * 0.7);

      if (scrollPosition + windowHeight >= scrollThreshold && !animationStarted) {
        setAnimationStarted(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationStarted]);

  useEffect(() => {
    if (animationStarted && activeIndex < iconData.length) {
      const timer = setTimeout(() => {
        setActiveIndex((prev) => prev + 1);
        if (activeIndex === 8) {
          setDotAnimationFinished(true);
        }
      }, 180);
      return () => clearTimeout(timer);
    }
  }, [activeIndex, animationStarted, iconData.length]);

  return (
    <>
    <div id="map" className="map-container">
      <div>
      <img src={MapImage1} className="map_icon1 hidden md:block" alt="Map Icon" />
      </div>
      <div className="map-heading ml-10 md:ml-40   font-dmsans text-4xl md:text-4xl font-bold text-center leading-relaxed md:leading-normal">
      <p  style={{ color: '#00836C' }} className="text-sm md:text-4xl">
        Map Integrity: Identifying Defects and Assets
      </p>
      </div>
     
      <div className="Map-animation hidden lg:block">
        {iconData.map((item, index) => {
          if (item.type === 'image') {
            return (
              <img
                key={index}
                src={item.src}
                alt={` Map Icon ${index}`}
                className={`${item.className} falling-icon ${
                  activeIndex > index && (dotAnimationFinished || index <= 7) ? 'visible' : ''
                }`}
              />
            );
          } else if (item.type === 'text') {
            return (
              <p
                key={index}
                className={`${item.className} falling-text ${
                  activeIndex > index && (dotAnimationFinished || index <= 7) ? 'visible' : ''
                }`}
              >
                {item.text}
              </p>
            );
          } else {
            return null; 
          }
        })}
      </div>

      {/* Mobile map image for screens smaller than md */}
      <div>
      <img src={Mobilemap} className="block lg:hidden mt-10 ml-5 md:ml-0" alt="Mobile Map" />
      </div>
    </div>
    </>
  );
};

export default Map;
