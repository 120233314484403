import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebook } from 'react-icons/fa';
import { AiFillTwitterCircle } from "react-icons/ai";
import { Link } from 'react-router-dom';
import './Footer1.css';

function Footer1() {
  return (
    <>
  
     <div
      className="relative bg-center text-black py- background-class"
      
    >
      <div className="absolute inset-0 w-full h-100 adoi"></div>
      <div className="relative container mx-auto px-4 mt- py- text-white">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
          {/* App Info Section */}
          <h2 className="follow text-xl font-bold mb-4 mt-4 md:mt-20 ml-32 font-inter text-white flex md:hidden" style={{fontWeight: 600}}>Follow Us</h2>
            <div className="social flex sm:justify-left space-x-6 mt- ml-20 flex md:hidden">
              <a href="https://www.linkedin.com/showcase/rasta.ai/" className="text-white hover:text-gray-300">
                <FaLinkedin className="w-6 h-6" />
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <FaInstagram className="w-6 h-6" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61563399991024&mibextid=LQQJ4d" className="text-white hover:text-gray-300">
                <FaFacebook className="w-6 h-6" />
              </a>
              <a href="https://x.com/rasta_ai_?s=21" className="text-white hover:text-gray-300">
                <AiFillTwitterCircle className="w-6 h-6" />
              </a>
            </div>
          
          <div className='font-inter'>
            <div className="shift  text-center mt-10 md:mt-20 font-josefin hidden md:block">
              <span className="shift  text-gray-300 font-bold text-3xl md:text-5xl">Rasta</span>
              <span className=" shift text-orange-400 font-bold text-3xl md:text-5xl">.Ai </span>
            </div>
            <p className=" shiftleft leading-8 max-w-prose line-clamp-7 ml-0 md:ml-16 font-semibold text-base font-inter mt-4 text-justify md:text-left px-4 md:px-0 hidden md:block" style={{fontWeight: 500}}>
            Rasta AI is an AI-powered road condition monitoring company making safe and pothole-free roads a reality, globally.
            </p>
            <h2 className=" text-xl font-bold mb-4 mt-10 md:mt-20 ml-20 font-inter text-white hidden md:flex" style={{fontWeight: 600}}>Follow Us</h2>
            <div className="flex sm:justify-left space-x-6 mt-4 ml-20 hidden md:flex">
              <a href="https://www.linkedin.com/showcase/rasta.ai/" className="text-white hover:text-gray-300">
                <FaLinkedin className="w-6 h-6" />
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <FaInstagram className="w-6 h-6" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61563399991024&mibextid=LQQJ4d" className="text-white hover:text-gray-300">
                <FaFacebook className="w-6 h-6"/>
              </a>
              <a href="https://x.com/rasta_ai_?s=21" className="text-white hover:text-gray-300">
                <AiFillTwitterCircle className="w-7 h-7" />
              </a>
            </div>
          </div>
          {/* Terms and Services Section */}
          <div className="  mt-0 md:mt-20 font-inter">
            <h2 className="company text-lg font-bold mb-1 md:mb-0 mt- md:mt-6 md:mt-20 ml-32 md:ml-20 text-white" style={{fontWeight: 630}}>Company</h2>
            <ul className=' flex  flex-row md:flex-col '>
              <Link to="/Privacy-policy">
                <li className="privacy ml-6 md:ml-20 font-semibold text-base mt-4 md:mt-4" style={{fontWeight: 500}}><a href="/terms" className="text-white hover:underline">Privacy Policies</a></li>
              </Link>
              <Link to="/Term-of-use">
                <li className="privacy md:mt-5 ml-16 md:ml-20 font-semibold text-base text-white hover:underline mt-4 md:mt-0" style={{fontWeight: 500}}><a href="/privacy">Terms of Service</a></li>
              </Link>
              <Link to="/Community-guidelines">
                <li className="mt-5 ml-28 md:ml-20 font-semibold text-base hidden md:block" style={{fontWeight: 500}}><a href="/refund" className="text-white hover:underline ">Community Guideline</a></li>
              </Link>
              <Link to="/Partner-program">
                <li className="mt-5 ml-28 md:ml-20 font-semibold text-base hidden md:block " style={{fontWeight: 500}}><a href="/refund" className="text-white hover:underline">Partner Program</a></li>
              </Link>
              
            </ul>
            <Link to="/Partner-program">
                <p className="partner1 mt-4 ml-28 md:ml-20 font-semibold text-base block md:hidden" style={{fontWeight: 500}}><a href="/refund" className="text-white hover:underline">Partner Program</a></p>
              </Link>
          </div>
          {/* About Us Section */}
          <div className="reachus mt-0 md:mt-5 font-inter ml-20 md:ml-0 ">
            <h2 className="  text-lg font-bold mb-4 mt-6 md:mt-20 font-inter  text-xl text-white ml-12 md:ml-0" style={{fontWeight: 630}}>Reach Us</h2>
            <p className="mt-4 md:mt-8"><a href="mailto:info@rasta360.com" className="text-white hover:underline ml-6 md:ml-0">info@rasta360.com</a></p>
            <p className="mt-4"><a href="mailto:contact@rasta-ai.com" className="text-white underline hover:underline ml-6 md:ml-0">contact@rasta-ai.com</a></p>
            <p className="mt-4"><a href="https://rastaai.in/home" className="text-white hover:underline ml-6 md:ml-0">www.rasta360.com</a></p>
            <h2 className="text-lg font-bold mb-4 mt-5 font-inter text-xl text-white hidden md:block" style={{fontWeight: 630}}>Trainings/Demo</h2>
            <Link to="/Faq">
              <p className="font-semibold text-base hover:underline hidden md:block" style={{fontWeight: 500}}>FAQ</p>
            </Link>
          </div>
          {/* Company Location Section */}
          <div className="mt-0 md:mt-5 font-inter ml-20 md:ml-0 hidden md:block text-center md:text-left">
            <h2 className="text-lg font-bold mb-4 mt-6 md:mt-20 font-inter text-xl text-white mr-10 " style={{fontWeight: 630}}>Head Office</h2>
            {/* <p className="mt-8 font-semibold text-base" style={{fontWeight: 500}}>903, Gera Imperium Rise</p> */}
            <p className="font-semibold text-base" style={{fontWeight: 500}}>Rajiv Gandhi Infotech Park,</p>
            <p className="font-semibold text-base" style={{fontWeight: 500}}>Hinjewadi Phase-2, Pune,57</p>
          </div>
        </div>
        <div className="shift  text-center mt-10 md:mt-20 font-josefin block md:hidden">
              <span className="shift  text-gray-300 font-bold text-3xl md:text-5xl">Rasta</span>
              <span className=" shift text-orange-400 font-bold text-3xl md:text-5xl">.Ai </span>
            </div>
        <div className="mt-2 md:mt-2 text-center text-white pt-4 font-semibold text-base" style={{fontWeight: 500}}>
        <p>© 2024 Rasta AI, All Rights Reserved. 
  <span className="hidden md:inline"> | </span>
  <Link to="/Contact">
    <span className="hidden md:inline mt-4 text-white underline hover:underline">Contact us</span>
  </Link>
</p>

        </div>
      </div>
    </div>
  
    </>
  );
}

export default Footer1;