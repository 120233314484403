import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import Footer1 from '../Footer/Footer1';
import { TbCircleLetterXFilled } from 'react-icons/tb';

function Communityguidelines({ onMenuToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  return (
   <>
   
   <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start"style={{ color: '#00836C'}}>
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>
     


<div className="bg-Honeydew relative pt-4 pb-8 mt-20">
			<h2 className="text-center text-4xl font-josefin mt-5 text-green-800 font-medium">
				Community Guidelines
			</h2>
			<h2 className="text-center text-xl font-josefin mt-5 text-green-800 font-medium">
			for rasta-Ai.com / rasta360.com
			</h2>
			<h2 className="text-center text-xl font-josefin mt-5 text-green-800 font-medium">
			Effective Date: 13/06/2024
			</h2>
			</div>

      <div className="p-8 px-40 bg-gray-100 font-josefin">
     

      <section>
        <h2 className="text-base font-semibold mb-4 mt-6">1. Introduction</h2>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          Welcome to the Community Initiative for Reporting Potholes, managed by rasta-ai.com. Our mission is to make our roads safer and free from potholes by leveraging the power of community participation and technology. Through the use of dashcams and reporting tools, citizens can contribute to identifying and reporting potholes, helping to facilitate prompt action by government authorities.
        </p>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          By participating in this initiative, you agree to comply with these community guidelines and terms, designed to ensure a positive and productive experience for all users. Additionally, by using this initiative, you provide consent to the collection, processing, and sharing of data as outlined below.
        </p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-4 mt-6">2. Eligibility</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li >This initiative is open to all citizens who use their vehicles or personal phones while traveling.</li>
          <li>Participants must be at least 18 years old to submit reports.</li>
          <li>Individuals under 18 may participate with parental consent.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-4 mt-6">3. Reporting Guidelines</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Provide accurate and relevant information when reporting potholes, including location, road surface details, and any additional observations.</li>
          <li>Use responsible language and conduct when communicating with government authorities and fellow community members.</li>
          <li>Avoid submitting multiple reports for the same pothole or location unless new information warrants additional reporting.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-4 mt-6">4. Data Usage and Privacy</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>When contributing reports, we may collect data from your phone camera, sensors, dashcam, and metadata, including location data, to analyze road conditions and identify potholes.</li>
          <li>Personal information such as ID cards may be requested for verification purposes.</li>
          <li>Your data will be anonymized and shared with government authorities to address pothole concerns. The privacy and security of your data are our top priorities.</li>
          <li>All data collected will be processed in compliance with our Privacy Policy.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-4 mt-6">5. Code of Conduct</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Respect the privacy and rights of others when reporting or communicating about road conditions.</li>
          <li>Refrain from sharing false or misleading information regarding potholes.</li>
          <li>Avoid using abusive or harmful language in interactions with community members or government authorities.</li>
          <li>Engage in positive and constructive feedback to enhance the overall experience.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-4 mt-6">6. Reporting Process</h2>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>Submit clear and comprehensive reports with relevant information and media to help facilitate action by the government authorities.</li>
          <li>Upon submission, we will review the reports and verify the accuracy before forwarding them to the appropriate government authorities.</li>
          <li>We will notify you about the successful submission and inform you of any subsequent actions taken by government authorities.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-4 mt-6">Terms of Participation</h2>

        <h3 className="text-sm font-semibold mb-2">1. Acceptance of Terms</h3>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          By participating in the Community Initiative for Reporting Potholes, you agree to abide by these community guidelines and terms. If you do not agree with any aspect of the guidelines and terms, please refrain from using this initiative.
        </p>

        <h3 className="text-sm font-semibold mb-2">2. User Content and Responsibilities</h3>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>You are responsible for providing accurate and truthful information in your reports.</li>
          <li>Any content that violates these guidelines or terms will not be accepted, and we reserve the right to remove such content.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">3. Intellectual Property</h3>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>By submitting content, including reports, photos, and videos, you grant rasta-ai.com a non-exclusive, royalty-free license to use, reproduce, and distribute the content for the purpose of addressing pothole concerns.</li>
          <li>You retain ownership of the content and all associated intellectual property rights.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">4. Third-Party Services</h3>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>We may share reports with government authorities or third-party service providers who assist in addressing pothole concerns.</li>
          <li>You acknowledge that these third parties have their own terms and conditions and privacy policies.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">5. Limitation of Liability</h3>
        <ul className="list-disc pl-6 mb-4 text-sm font-light"style={{fontWeight: 350}}>
          <li>rasta-ai.com is not liable for any inaccurate, misleading, or false reports provided by community members.</li>
          <li>Our responsibility is limited to facilitating the reporting process and forwarding valid reports to government authorities.</li>
        </ul>

        <h3 className="text-sm font-semibold mb-2">6. Changes to Terms</h3>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          rasta-ai.com reserves the right to modify, revise, or update these community guidelines and terms at any time. Any changes will be posted on the website with an updated effective date.
        </p>
      </section>

      <section>
        <h2 className="text-base font-semibold mb-4  mt-6">Additional Information</h2>

        <h3 className="text-base font-semibold mb-2 mt-6">7. Privacy Policy</h3>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          Your privacy and the security of your data are paramount. Please review our Privacy Policy for more information on data usage and protection.
        </p>

        <h3 className="text-base font-semibold mb-2 mt-6">8. Contact Information</h3>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          If you have any questions or concerns regarding the community guidelines and terms, please contact us at:
        </p>
        <address className="not-italic mb-4 text-sm font-light"style={{fontWeight: 350}}>
          Ai Unika Technologies (P) Limited<br />
          903, Rajiv Gandhi Infotech Park, Hinjewadi Phase 2, Pune (MH) India 57<br />
          Email: <a href="mailto:support@rasta-aiunika.com" className="text-blue-600 underline">support@rasta-aiunika.com</a>
        </address>

        <h3 className="text-base font-semibold mb-2 mt-6">9. Consent</h3>
        <p className="mb-4 text-sm font-light"style={{fontWeight: 350}}>
          I accept and agree to these community guidelines and terms, and I understand that by clicking this, I consent to Ai Unika Technologies (P) Ltd using my data and name for community purposes.
        </p>
      </section>
    </div>
    <Footer1 />

   </>
  )
}

export default Communityguidelines