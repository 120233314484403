import React from 'react';
import './spinner.css';
import Spinnerlogo from '../../assets/spinner/spinnerlogo.png'

const Spinner = () => {
    return (
        <div className="loader">
        <div className="logo-animation">
          <img data-aos="zoom-in" src={Spinnerlogo} alt="spinner logo" />
        </div>
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
};

export default Spinner;