import React, { useState } from 'react';
import { FaCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Newlyintroduced from '../../assets/scooter/Newintroduced.png';

import Mini from '../../assets/Ourproducts/Mini.png';
import Scooterriding from '../../assets/scooter/Scooterriding.png'
import { Link } from 'react-router-dom';
import './Secondpage.css'
import Mobilelaptop from '../../assets/mobilescooter/mobilelaptop.svg';
import Mobiletraining from '../../assets/mobilescooter/mobilemeeting.svg';
import Mobilescooter from '../../assets/mobilescooter/mobilescooter.svg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




function Secondpage() {
  
 
    const carouselImages2 = [
      Mobilelaptop,
      Mobiletraining,
      Mobilescooter
    ];
  
   
  
    
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
    autoplaySpeed: 3000 
    };
  
  return (
   <>
   <div className='overflow-x-hidden '>
   <div className="md:flex justify-between items-center">
   
   
         
    <img 
        src={Newlyintroduced}
        alt="New Introduced"
        className="mt-14 hidden md:block"
        style={{ maxWidth: '16%', maxHeight: '16%' }}
        />
    <div className="my- pb-  md:text-right ">
    <div className="scootermargin text-center ml-0 md:ml-0 md:mr-80">
     
    <div className="text-center font-josefin mt-10 md:mt-20 ">
    <span className="text-gray-500 font-bold text-2xl md:text-4xl">Rasta</span>
    <span className="text-orange-500 font-bold text-2xl md:text-4xl">.360&deg; </span>
    <span className="text-green-500 font-bold text-2xl md:text-4xl"style={{color: "#00836C"}}>Mini</span>
    <p className=" text-center mt-2 text-base md:text-2xl font-opensans" style={{ fontWeight: 350}}>The Breakthrough in Road Monitoring</p>
          </div>
            </div>
            </div>

    </div>

          <div>
    
    <div className="p- box-content">
        <div className="flex flex-col md:flex-row justify-between items-start p-6">
            <div className="md:w-1/2  md:justify-center md:justify-start md:items-center mt-10 md:mt-0">
                {/* Insert any content for the left side here if needed */}
                <img
      src={Scooterriding}
      alt="MiniLaptop"
      className="w-96 h-auto ml-0 md:ml-20"
      />
      <Link to="/Contact">
       <button 
              className="block mx-auto mt-10 px-6 py-3 text-white text-xl md:text-2xl rounded font-josefin m1-20 md:ml-40" 
              style={{ backgroundColor: '#00836C', borderRadius: '1rem', fontWeight: 600 ,whiteSpace: 'nowrap'}}
            >
              Contact Sales
            </button>
            </Link>
            </div>
            <div className="space-y-8 p- md:w-1/2 md:ml-10 mt-10 md:mt-0">
                <div className="flex items-start space-x-4">
                    <div className="flex flex-col items-center gap-y-2">
                        <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                        <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                    </div>
                    <div className="flex flex-col gap-y-5 font-opensans">
                        <h3 className="text-base md:text-xl font-semibold">Cost-Effective</h3>
                        <p className='text-xs md:text-base'>Offers a cost effective alternating for road monitoring compared to traditional methods.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <div className="flex flex-col items-center gap-y-2">
                        <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                        <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                    </div>
                    <div className="flex flex-col gap-y-5 font-opensans">
                        <h3 className="text-base md:text-xl font-semibold">User-Friendly-Surveys</h3>
                        <p className='text-xs md:text-base'>Survey roads with any vehicle using Rasta360&deg; Mini Kit,no specialized vehicles needed.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <div className="flex flex-col items-center gap-y-2">
                        <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                        <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                    </div>
                    <div className="flex flex-col gap-y-5 font-opensans">
                        <h3 className="text-base md:text-xl font-semibold">Scalabiltiy</h3>
                        <p className='text-xs md:text-base'>Visualize entire city data on map within a couple of hours using multiple survey kits.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <div className="flex flex-col items-center gap-y-2">
                        <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                        <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                    </div>
                    <div className="flex flex-col gap-y-7 font-opensans">
                        <h3 className="text-base md:text-xl font-semibold">Mini Kit</h3>
                        <p className='text-xs md:text-base'>Mini Kits attach easilt to Construction helmets,bikes and cars for quick data collection.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <div className="flex flex-col items-center gap-y-2">
                        <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                        <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                    </div>
                    <div className="flex flex-col gap-y-7 font-opensans">
                        <h3 className="text-base md:text-xl font-semibold">Connectivity</h3>
                        <p className='text-xs md:text-base'>A mobile app and web dashboard connected kit.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <div className="flex flex-col items-center gap-y-2">
                        <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                        <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                    </div>
                    <div className="flex flex-col gap-y-7 font-opensans">
                        <h3 className="text-base md:text-xl font-semibold">User-Friendly Dashboard</h3>
                        <p className='text-xs md:text-base'>The portal offers detailed road network analytics with on-click 360&deg; issue visualization.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="enterprise text-center mt-10 text-xl md:text-4xl text-green-700 text-semibold font-dmsans" style={{ fontWeight: 500 }}>
<h2>Why Enterprise</h2>
      </div>
      <div className="md:hidden">
      <Slider {...slickSettings}>
      <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Cost Effective</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                Rasta360° Mini offers an affordable road monitoring option, significantly reducing costs compared to traditional methods.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                Survey roads with any vehicle, thereby effectively minimizing the necessity for special transformation and ongoing maintenance costs, labor, and time requirements.
                </li>
              </ul>
            </div>
          </div>
          <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Data Collection</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                Rasta360° Mini enables effortless, streamlined data collection with any vehicle, even in remote, difficult to access areas.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                Data can be processed and thoroughly analyzed within 180 minutes of upload, providing timely insights for prompt and effective decision-making processes.
                </li>
              </ul>
            </div>
          </div>
          <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Accessibility</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                Rasta360° Mini facilitates effortless data collection with any vehicle, even in remote, challenging-to-access urban and rural areas.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                Data collection does not necessarily require trained professionals; anyone can easily collect data using the provided, intuitive, and user-friendly solution.
                </li>
              </ul>
            </div>
          </div>
</Slider>
</div>
   
<div className=" hidden md:flex Rasengan1 flex justify-around items-center p-5 font-opensans font-light leading-normal mt-8" style={{ fontWeight: 350, height: '60vh' }}>
  <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
    <h2 className="text-2xl font-bold text-opensans">Cost Effective</h2>
    <ul className="list-disc list-inside mt-8 text-base ">
      <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
      Rasta360° Mini offers an affordable road monitoring option, significantly reducing costs compared to traditional methods.
      </li>
      <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
      Survey roads with any vehicle, thereby effectively minimizing the necessity for special transformation and ongoing maintenance costs, labor, and time requirements.






      </li>
    </ul>
  </div>
  <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
    <h2 className="text-2xl font-bold text-opensans">Data Collection</h2>
    <ul className="list-disc list-inside mt-8 text-base ">
      <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
      Rasta360° Mini enables effortless, streamlined data collection with any vehicle, even in remote, difficult to access areas.


      </li>
      <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
      Data can be processed and thoroughly analyzed within 180 minutes of upload, providing timely insights for prompt and effective decision-making processes.






      </li>
    </ul>
  </div>
  <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
    <h2 className="text-2xl font-bold text-opensans">Accessibility</h2>
    <ul className="list-disc list-inside mt-8 text-base ">
      <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
      Rasta360° Mini facilitates effortless data collection with any vehicle, even in remote, challenging-to-access urban and rural areas.
      </li>
      <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
      Data collection does not necessarily require trained professionals; anyone can easily collect data using the provided, intuitive, and user-friendly solution.






      </li>
    </ul>
  </div>
</div>

      <div className="block md:hidden Renegan1 text-center mt-24 text-xl md:text-4xl text-green-700 font-semibold font-dmsans" style={{ fontWeight: 500 }}>
        <h2>Ask for Demo</h2>
      </div>

      <div className="mobile-carousel md:hidden text-center mt-10 relative mb-14 md:mb-0">
        <Slider {...slickSettings}>
          {carouselImages2.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Slide ${index}`} className="carousel-image px-20" />
            </div>
          ))}
        </Slider>
      </div>

      <div className="hidden md:block Renegan1 text-center mt-10 text-2xl md:text-4xl text-green-700 font-semibold font-dmsans" style={{ fontWeight: 500 }}>
        <h2>Ask for Demo</h2>
      </div>
      {/* Conditionally render the demo image based on screen size */}
      {window.innerWidth >= 768 && (
        <div className="pb-40 text-center md:px-20">
          <img
            src={Mini}
            alt="demo image"
            className="mt-20 mx-auto"
          />
        </div>
 )}
</div>

   </>
  )
}

export default Secondpage