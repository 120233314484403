// src/components/GoToTopButton.js

import React, { useState, useEffect } from 'react';
import './Gototop.css'; // We'll add styles here
import { TiArrowUpThick } from "react-icons/ti";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { PiArrowCircleUpFill } from "react-icons/pi";

const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="go-to-top">
      {isVisible && 
        <button onClick={scrollToTop} className="-to-top-button">
          <PiArrowCircleUpFill className=" text-orange-500 hidden md:block md:w-14 md:h-14"  />
        </button>
      }
    </div>
  );
};

export default GoToTopButton;
