import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Mobile from '../../assets/Ourproducts/Mobile.png';
import Rastaprocess from '../../assets/Ourproducts/Rastaprocess.png';
import Group from '../../../src/assets/Dataflow/Group.png';
import Mobiledownload from '../../assets/Thirdpage/mobiledownload.svg';
import Subscriptionmobile from '../../assets/Thirdpage/subscriptionmobile.svg';
import Carmobile from '../../assets/Thirdpage/carmobile.svg';
import './Thirdpage.css';

function Thirdpage() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000, 
    appendDots: dots => (
      <div>
        <ul className="custom-dots"> {dots} </ul>
      </div>
    )
  };

  return (
    <>
    <div className='overflow-x-hidden overflow-y-hidden'>
      <div className="text-center mt-10 md:mt-16 md:mt-0 font-josefin">
        <span className="text-gray-500 font-bold text-2xl md:text-4xl">Rasta</span>
        <span className="text-orange-500 font-bold text-2xl md:text-4xl">.AI </span>
        <p className="text-center mt-2 text-sm md:text-2xl font-opensans" style={{ fontWeight: 350 }}>
          Faster, affordable data collection and processing
        </p>
      </div>

      <div>
        <p className="font-josefin text-2xl font-medium mx-auto md:ml-40 text-center md:text-left mt-" style={{ fontWeight: 600 }}> </p>
        <div className="p-6 box-content">
          <div className="flex flex-col md:flex-row justify-between ml-4 md:ml-0 items-start p-">
            <div className="space-y-8 p-2 md:w-1/2 md:ml-10">
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-5 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Offline Data Collection</h3>
                  <p className="text-xs md:text-base">Enables data collection offline, syncing automatically once connectivity is restored.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-5 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Real Time Road Condition Alerts</h3>
                  <p className="text-xs md:text-base">Provides instant notifications about road issues like potholes and cracks.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-5 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">On Screen View</h3>
                  <p className="text-xs md:text-base">During the survey, users can view accurate data collection on-screen.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-7 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Instant Data Access For Authorities</h3>
                  <p className="text-xs md:text-base">Higher authorities can view the data with one click, as per the jurisdiction.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-7 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">Data Authenticity</h3>
                  <p className="text-xs md:text-base">Uploaded data is plotted on a map with location and image authenticity.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="flex flex-col items-center gap-y-2">
                  <FaCircle className="w-4 h-4" style={{ color: '#087567' }} />
                  <p className="border-l-[2px] h-12" style={{ borderColor: '#087567' }}></p>
                </div>
                <div className="flex flex-col gap-y-7 font-opensans">
                  <h3 className="text-base md:text-xl font-semibold">User Friendly Interface</h3>
                  <p className="text-xs md:text-base">A user-friendly interface for easy interaction and navigation.</p>
                </div>
              </div>
            </div>
            <div className="photo1 md:w-1/2 justify-center md:justify-end items-center mt-6 md:mt-0">
              <img
                src={Group}
                alt="mobile photo"
                className=" max-w-80 md:max-w-96 h-auto mt-26 md:ml-40 "
              />
              <Link to="/Contact">
                <button
                  className="block mx-auto mt-10 px-6 py-3 text-white text-xl md:text-2xl rounded font-josefin ml-70"
                  style={{ backgroundColor: '#00836C', borderRadius: '1rem', fontWeight: 600, whiteSpace: 'nowrap' }}
                >
                  Contact Sales
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-10 text-xl md:text-4xl text-green-700 text-semibold font-dmsans" style={{ fontWeight: 500 }}>
        <h2>Why Rasta.AI App</h2>
      </div>

      <div className="md:hidden">
        <Slider {...settings}>
          <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Subscription Model</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                  The app's subscription model offers tailored subscription plans with advanced features for highly efficient road monitoring.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                  Subscribers benefit from seamless data collection and analysis, optimizing roads repair and maintenance strategies.
                </li>
              </ul>
            </div>
          </div>
          <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Scalable</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                  The scalable road monitoring solution supports extensive data gathering thorough analysis, and efficient management.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                  Efficiently manages expansive large-scale road networks, accommodating diverse and complex survey needs.
                </li>
              </ul>
            </div>
          </div>
          <div className="p-5">
            <div className="border-2 border-green-700 p-4 pt-8 pb-8 text-center shadow-lg shadow-green-700/50">
              <h2 className="text-xl font-bold text-opensans">Accessibility</h2>
              <ul className="list-disc list-inside mt-8 text-sm">
                <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
                  An uninterrupted solution for data collection from any vehicle, providing comprehensive, accurate, and efficient road monitoring.
                </li>
                <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
                  User-friendly interfaces simplify road condition monitoring, enhancing efficiency and usability for all involved.
                </li>
              </ul>
            </div>
          </div>
        </Slider>
      </div>

      <div className="hidden md:flex justify-around items-center p-5 font-opensans font-light leading-normal mt-10" style={{ fontWeight: 350, height: '60vh' }}>
        <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
          <h2 className="text-2xl font-bold text-opensans">Subscription Model</h2>
          <ul className="list-disc list-inside mt-8 text-base">
            <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
              The app's subscription model offers tailored subscription plans with advanced features for highly efficient road monitoring.
            </li>
            <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
              Subscribers benefit from seamless data collection and analysis, optimizing roads repair and maintenance strategies.
            </li>
          </ul>
        </div>
        <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
          <h2 className="text-2xl font-bold text-opensans">Scalable</h2>
          <ul className="list-disc list-inside mt-8 text-base">
            <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
              The scalable road monitoring solution supports extensive data gathering thorough analysis, and efficient management.
            </li>
            <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
              Efficiently manages expansive large-scale road networks, accommodating diverse and complex survey needs.
            </li>
          </ul>
        </div>
        <div className="border-2 border-green-700 p-4 pt-8 pb-8 w-1/3 m-2 text-center shadow-lg shadow-green-700/50 flex flex-col justify-between">
          <h2 className="text-2xl font-bold text-opensans">Accessibility</h2>
          <ul className="list-disc list-inside mt-8 text-base">
            <li className="text-justify mx-auto" style={{ maxWidth: '90%' }}>
              An uninterrupted solution for data collection from any vehicle, providing comprehensive, accurate, and efficient road monitoring.
            </li>
            <li className="text-justify mx-auto mt-6" style={{ maxWidth: '90%' }}>
              User-friendly interfaces simplify road condition monitoring, enhancing efficiency and usability for all involved.
            </li>
          </ul>
        </div>
      </div>

	  <div className="text-center mt-24 text-xl md:text-4xl text-green-700 text-semibold font-dmsans block md:hidden" style={{ fontWeight: 500 }}>
        <h2>Process For Mobile application</h2>
      </div>

      <div className="block md:hidden mb-14 md:mb-0">
        {/* Slider for mobile view */}
        <Slider {...settings}>
          <div className="p-5">
            <img src={Mobiledownload} alt="Mobile Download" className="mx-auto max-w-full h-85 px-14" />
          </div>
          <div className="p-5">
            <img src={Subscriptionmobile} alt="Subscription Mobile" className="mx-auto max-w-full " />
          </div>
          <div className="p-5 px-5">
            <img src={Carmobile} alt="Car Mobile" className="mx-auto max-w-full " />
          </div>
        </Slider>
      </div>

     
      <div className="text-center mt-40 text-4xl text-green-700 text-semibold font-dmsans hidden md:block" style={{ fontWeight: 500 }}>
        <h2>Process For Mobile application</h2>
      </div>

      <div className="pb-40 text-center md:px-8 hidden md:block">
        <img
          src={Rastaprocess}
          alt="demo image"
          className="mt-20 mx-auto"
        />
      </div>
      </div>
    </>
  );
}

export default Thirdpage;
