// src/components/AccountDeletionRequest.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../apiConnection';

const AccountDeletionRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}accountDelete/request-account-deletion`, { email });
            setMessage('Deletion email sent successfully. Please check your inbox.');
        } catch (error) {
            setMessage('Error sending deletion email. Please try again.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h1 className="text-2xl font-semibold text-center text-gray-700 mb-6">Request Account Deletion</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email Address
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 "
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-500 focus:outline-none focus:bg-orange-500"
                    >
                        Send Deletion Email
                    </button>
                </form>
                {message && <p className="mt-4 text-center text-gray-600">{message}</p>}
            </div>
        </div>
    );
};

export default AccountDeletionRequest;
