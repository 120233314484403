import OurPartners from '../OurPartners/OurPartners';
import HomePage from '../HomePage/Core/HomePage';
import SupportedPage from '../SupportedPage/SupportedPage';
import DefectPage from '../DefectPage/DefectPage'; 
import Annotation from '../DefectPage/Annotation/Annotation';
import DataFlow from '../DataFlow/DataFlow';
import Footer1 from '../Footer/Footer1';
import Contact_us from '../Contact_us/Contact_us';
import Scooter from '../Scooter/Scooter';
import Bridge from '../Bridgeimage/Bridge';
 import Map from '..//Map/Map';
 import { useLayoutEffect } from 'react';
 import ScrollToTop from '../ScrollToTop';

 
function App() {

	
	return (
		
		<div className="App overflow-x-hidden scroll-smooth">
			{/* <ScrollToTop /> */}
			<HomePage />
			 <Map />
			 <DefectPage />
			
			<Bridge />
			<Contact_us />
			
			
			<OurPartners />
			<SupportedPage/>
			 
			 
			 <Footer1 />  
			 
			 
			  
		</div>
		
	);
}

export default App;
