import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PopupModal } from "react-calendly";
import Contactbackground from '../../assets/Partnerprogram/Contactbackground.svg'
import '../HomePage/Navbar/NavbarStyle.css'
import { TbCircleLetterXFilled } from 'react-icons/tb';
import Footer1 from '../Footer/Footer1';
import toast, { Toaster } from "react-hot-toast";


const Appointment = ({ onMenuToggle }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(!menuOpen);
    }
  };

  const handleLinkClick = () => {
    //console.log("Clicked"); // Log a message to the console
    setMenuOpen(false); // Close the menu when a link is clicked
    if (typeof onMenuToggle === 'function') {
      onMenuToggle(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    jobRole: '',
    industry: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., send data to backend
    console.log(formData);
    // Clear form fields after submission if needed
    setFormData({
      firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            country: '',
            jobRole: '',
            industry: '',
    });
  };
  const submitform  = () =>{
    fetch("https://rastaaiunika.com/contact-rasta/put-contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((response) =>
      response.json().then((response) => {
        if (response.msg=="Form Submitted") {
          toast.success("Form Submitted successfully");
         
         
        } else {
          toast.error("Something went wrong");
        }
      })
    );
  }
  return (
    <>
  <div>
        <Toaster />
      </div>
    <div className="text-orange-500 w-full   flex justify-between items-center mt-4 md:mt-2 ">
    <div className="cursor-pointer">
      <Link to="/">
        <p className='flex items-center text-2xl ml-10 font-bold text-gray-500'>
          Rasta
          <div className="rotating-text-container "style={{ color: '#FF6F17' }}>
          <div className="rotating-text">.360°</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text"style={{ color: '#FF6F17' }}>.360°<span className=" text-green-500">.Mini</span></div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			<div className="rotating-text">.360</div>
            <div className="rotating-text">.AI</div>
            <div className="rotating-text">.Mini</div>
			
          </div>
        </p>
      </Link>
    </div>
    <ul className="hidden md:flex h-18 flex items-center my-auto  justify-end gap-x-12 pr-12 text-gray-400 font-semibold cursor-pointer font-josefin mt-4">
    <Link to="/Ourproducts">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start ">
            Our Products
        </li>
        </ Link>
        <Link to="/Demo">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            Demo
        </li>
        </ Link>
        <Link to="/Contact">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start"style={{ color: '#00836C'}}>
            Contact
        </li>
        </Link>
        <Link to="/Aboutus">
				<li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
					About us
				</li>
				</Link>
        <Link to="/Faq">
        <li className="hover:scale-110 transition-all duration-200 ease-in-out text-xl flex items-start">
            FAQ
        </li>
        </Link>
        
    </ul>
      {/* Apply md:hidden to hide on screens md and larger */}
      <button className="md:hidden mobile-menu-button text-2xl mr-5" style={{ color: 'grey' }} onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-green-900 bg-opacity-90 flex flex-col items-start p-8 space-y-12 z-50"
          style={{ backgroundColor: 'rgba(8, 117, 103, 0.9)' }}
        >
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleMenu}>
            <TbCircleLetterXFilled />
          </button>
          <Link to="/" className="text-white mt-24 text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/Ourproducts" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Our Products
          </Link>
          <Link to="/Demo" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Demo
          </Link>
          <Link to="/Contact" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            Contact
          </Link>
          <Link to="/Aboutus" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            About us
          </Link>
          <Link to="/Faq" className="text-white text-2xl border-b border-white pb-2 px-4 w-full" onClick={handleLinkClick}>
            FAQ
          </Link>
        </div>
      )}
    </div>

        <div className="hidden md:block flex mt-0 md:mt-40 max-w-6xl mx-auto">
  <div className="w-full bg-green-200 overflow-hidden shadow-md flex">
    <div 
      className="w-1/2 relative bg-cover bg-center text-white flex items-center justify-center p-8"
      style={{ 
        backgroundImage: `url(${Contactbackground})`,
        borderRadius: 'none', // Remove rounded corners
      }}
    >
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-4">Get in Touch</h2>
        <p className="text-lg mb-6">Schedule a meeting to learn more about Rasta.ai and discover how our solutions can benefit your road monitoring needs.</p>
        <button
          className="bg-green-700 px-6 py-4 rounded-xl text-white text-lg font-semibold"
          onClick={() => setIsOpen(true)}
        >
          Schedule Meeting
        </button>
      </div>
    </div>

    <div className="w-1/2 p-8">
      <h2 className="text-2xl font-semibold mb-4 text-center text-black font-dmsans">Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 font-opensans">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
              style={{ height: 'auto' }} // Allow input height to adjust based on content
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
              style={{ height: 'auto' }} // Allow input height to adjust based on content
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Work Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
              style={{ height: 'auto' }} // Allow input height to adjust based on content
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Phone Number (with Country Code)
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
              style={{ height: 'auto' }} // Allow input height to adjust based on content
            />
          </div>
          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country/Region
            </label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
              style={{ height: 'auto' }} // Allow input height to adjust based on content
            />
          </div>
          <div>
            <label htmlFor="jobRole" className="block text-sm font-medium text-gray-700">
              Job Role
            </label>
            <input
              type="text"
              id="jobRole"
              name="jobRole"
              value={formData.jobRole}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2 px-3" // Reduced padding vertically
              style={{ height: 'auto' }} // Allow input height to adjust based on content
            />
          </div>
          <div className="flex justify-center">
            <button
              type="button" onClick={submitform}
              className="bg-green-700 px-6 py-3 rounded-md text-white font-semibold hover:bg-green-600 focus:bg-green-600 focus:outline-none"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


      <PopupModal
        url="https://calendly.com/rahul-aiunika"
        // Pass additional props as needed
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")} // Ensure this matches your root element
      />

<div className="block md:hidden  mt-20 max-w-6xl mx-auto space-y-8 px-8 md:px-0">
  {/* Contact Us Form */}
  <div className="w-full bg-green-200 overflow-hidden shadow-md flex flex-col p-8  ">
    <h2 className="text-2xl font-semibold mb-4 text-center text-black font-dmsans">Contact Us</h2>
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 font-opensans">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
            style={{ height: 'auto' }}
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
            style={{ height: 'auto' }}
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Work Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
            style={{ height: 'auto' }}
          />
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone Number (with Country Code)
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
            style={{ height: 'auto' }}
          />
        </div>
        <div>
          <label htmlFor="country" className="block text-sm font-medium text-gray-700">
            Country/Region
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
            style={{ height: 'auto' }}
          />
        </div>
        <div>
          <label htmlFor="jobRole" className="block text-sm font-medium text-gray-700">
            Job Role
          </label>
          <input
            type="text"
            id="jobRole"
            name="jobRole"
            value={formData.jobRole}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-700 focus:ring focus:ring-green-700 focus:ring-opacity-50 sm:text-sm py-2"
            style={{ height: 'auto' }}
          />
        </div>
        <div className="flex justify-center">
          <button
            type="button" onClick={submitform}
            className="bg-green-700 px-6 py-3 rounded-md text-white font-semibold hover:bg-green-600 focus:bg-green-600 focus:outline-none"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>

  {/* Get in Touch Section */}
  <div 
    className="w-full relative bg-cover bg-center text-white flex items-center justify-center p-8  md:pb-0"
    style={{ 
      backgroundImage: `url(${Contactbackground})`,
      borderRadius: 'none', // Remove rounded corners
    }}
  >
    <div className="text-center">
      <h2 className="text-xl md:text-4xl font-bold mb-4">Get in Touch</h2>
      <p className="text-sm md:text-lg mb-6">Schedule a meeting to learn more about Rasta.ai and discover how our solutions can benefit your road monitoring needs.</p>
      <button
        className="bg-green-700 px-6 py-4 rounded-xl text-white text-xs md:text-lg font-semibold"
        onClick={() => setIsOpen(true)}
      >
        Schedule Meeting
      </button>
    </div>
  </div>

  {/* Calendly PopupModal */}
  <PopupModal
    url="https://calendly.com/rahul-aiunika"
    onModalClose={() => setIsOpen(false)}
    open={isOpen}
    rootElement={document.getElementById("root")}
  />
</div>




<div className='mt-10 md:mt-20 '>
<Footer1 />
</div>
    </>
  );
};

export default Appointment;